import { useState } from 'react';

export default function useApi(size /* create, read, update, destroy, list */) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [maxItemCount, setMaxItemCount] = useState(0);
  const [itemAmount, setItemAmount] = useState(size || 10);
  const [waiting, setWaiting] = useState(false);

  const nextPage = (last = false) => {
    if (page === maxPage) {
      return;
    }
    setPage(last ? maxPage : page + 1);
  };

  const prevPage = (first = false) => {
    if (page === 0) {
      return;
    }
    setPage(first ? 0 : page - 1);
  };

  return {
    data,
    setData,
    waiting,
    setWaiting,
    pagination: {
      setPage,
      page,
      maxPage,
      setMaxPage,
      maxItemCount,
      setMaxItemCount,
      nextPage,
      prevPage,
      itemAmount,
      setItemAmount,
    },
  };
}
