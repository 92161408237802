import { TTrainerInfo, TTrainerInfoUI } from '../types/animalTraining.types';
import { TAuthConverter } from '../types/converter.types';

export const trainerInfoConvertor: TAuthConverter<TTrainerInfoUI, TTrainerInfo, TTrainerInfoUI, TTrainerInfo> = {
  toDb: (data) => {
    return {
      name: data.name,
      emailAddress: data.emailAddress?.trim() || undefined,
      phoneNumber: data.phoneNumber ? `${data.phoneNumber[0] === '+' ? '' : '+'}${data.phoneNumber}` : undefined,
      faxNumber: data.faxNumber?.trim() || undefined,
    };
  },
  fromDb: (data) => {
    return {
      name: data.name,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber,
      faxNumber: data.faxNumber,
    };
  },
};
