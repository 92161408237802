import {
  TAnimalBasicInfo,
  TAnimalBasicInfoUi,
  TAnimalOwnerInfoDB,
  TAnimalOwnerInfoUI,
  TDataOverviewDB,
  TDataOverviewUI,
} from './animals.types';
import { TSADocPacketGet, TSADocPacketGetUI } from './animalTraining.types';
import { TDocPacketGet, TDocPacketGetUI } from './document.types';
import { TOptionTypeUi, TStatusChangeInfoDB, TStatusChangeInfoUI } from './general.types';
import { EAnimalResidencyStatusDB, EAnimalResidencyStatusUI } from './residence.types';
import { TAnimalVetRecord, TAnimalVetRecordUi } from './vetRecord.types';

export enum EMethodDB {
  SIMPLE = 'SIMPLE',
  APPROVE_WITHOUT_TENANT_RESIDENCY = 'APPROVE_WITHOUT_TENANT_RESIDENCY',
  APPROVE_WITH_EXISTING_TENANT_RESIDENCY = 'APPROVE_WITH_EXISTING_TENANT_RESIDENCY',
}

export enum EMethodUI {
  SIMPLE = 'SIMPLE',
  APPROVE_WITHOUT_TENANT_RESIDENCY = 'APPROVE_WITHOUT_TENANT_RESIDENCY',
  APPROVE_WITH_EXISTING_TENANT_RESIDENCY = 'APPROVE_WITH_EXISTING_TENANT_RESIDENCY',
}

export type TAppMethodDetailsUI = {
  leaseToDate: string | null;
  moveInDate: string | null;
  property: TAppPropertyUI;
  policy: TAppPolicyUI;
  unit: TAppUnitDB;
};

export type TAppMethodDetailsDB = {
  leaseToDate: string | null;
  moveInDate: string | null;
  policyId: string;
  policyName: string;
  propertyId: string;
  propertyName: string;
  unitId: string;
  unitNumber: string;
};

export type TWeightProps = {
  unit: string;
  value: string;
};

export type TAppPropertyDB = {
  propertyName: string;
  propertyId: string;
};

export type TAppPropertyUI = TAppPropertyDB;

export type TAppPolicyDB = {
  policyName: string;
  policyId: string;
};

export type TAppPolicyUI = TAppPolicyDB;

export type TAppUnitDB = {
  unitNumber: string;
  unitId: string;
};

export type TAppUnitUI = TAppUnitDB;

export type TAppResidentDB = {
  residentPhone: string | null;
  residentName: string;
  residentEmail: string;
  residentProfilePicture: string | null;
};

export type TAppResidentUI = TAppResidentDB;

export type TSpeciesProps = {
  predefined: boolean;
  predefinedId: string;
  value: string;
};

export type TAnimalResidenciesDetailsDB = {
  id: string;
  animalName: string;
  animalProfilePicture: string;
  animalType: string;
  animalWeight: TWeightProps;
  animalSpecie: TSpeciesProps;
};

export type TAnimalResidenciesDetailsUI = TAnimalResidenciesDetailsDB;

export type TTenantResidenciesDetailsDB = {
  id: string;
  unitNumber: string;
  propertyName: string;
  animalResidencies: TAnimalResidenciesDetailsDB[];
};

export type TTenantResidenciesDetailsUI = {
  id: string;
  unitNumber: string;
  propertyName: string;
  animalResidencies: TAnimalResidenciesDetailsUI[];
};

export type TTenantResidenciesDB = { residencies: TTenantResidenciesDetailsDB[] };

export type TApplicationDetailDB =
  | {
      residencies?: TTenantResidenciesDetailsDB[];
    }
  | TAppMethodDetailsDB;

export type TApplicationDetailUI =
  | {
      residencies?: TTenantResidenciesDetailsUI[];
    }
  | TAppMethodDetailsUI;

export type TApplicationDetailsUI = {
  resident: TAppResidentDB;
  method: string;
  details: any | TApplicationDetailUI | null;
};

export type TApplicationDetailsDB = {
  residentPhone: string;
  residentName: string;
  residentEmail: string;
  residentProfilePicture: string;
  method: EMethodDB;
  details: { [key in EMethodDB]: TApplicationDetailDB };
};

export type TAnimalApplicantDetail = {
  animal: TAnimalBasicInfo;
  ownerInfo: TAnimalOwnerInfoDB;
  vetRecords: TAnimalVetRecord[] | null;
  hpDocPackets: TDocPacketGet[] | null;
  residencyStatus: EAnimalResidencyStatusDB;
  residencyStatusChangeInfo: TStatusChangeInfoDB | null;
  saDocuments: TSADocPacketGet[] | null;
  dataOverview: TDataOverviewDB;
};

export type TAnimalApplicantDetailUi = {
  animal: TAnimalBasicInfoUi;
  ownerInfo: TAnimalOwnerInfoUI;
  vetRecords: TAnimalVetRecordUi[] | null;
  hpDocPackets: TDocPacketGetUI[] | null;
  residencyStatus: EAnimalResidencyStatusUI;
  residencyStatusChangeInfo: TStatusChangeInfoUI | null;
  saDocuments: TSADocPacketGetUI[] | null;
  dataOverview: TDataOverviewUI;
};

export type TApproveApplicationFormProps = {
  propertyId: string;
  unitNumber: string;
  moveInDate: string;
  leaseToDate: string;
};

export type TApproveApplicationFormPropsUI = {
  property: TOptionTypeUi;
  unit: TOptionTypeUi;
  moveInDate: string;
  leaseToDate: string;
};

export type TApproveApplicationUI = {
  applicationId: string;
  residenceId?: string;
  residencyDetails?: TApproveApplicationFormPropsUI;
};

export type TApproveApplicationDB = {
  applicationId: string;
  residenceId?: string;
  residencyDetails?: TApproveApplicationFormProps;
};

export type TApproveApplication = {
  residencyDetails: TApproveApplicationFormProps;
};

export type TReasonProp = {
  [reason in string]: string;
};

export type TVerificationMessageUi = {
  message?: string;
};

export type TVerificationMessageDB = {
  result: TReasonProp[];
};
