import { TConvertor, TFetchConverter } from '../types/converter.types';
import { TOptionTypeUi } from '../types/general.types';
import { TDocHtmlUI, TPolicyOptionType } from '../types/policy.types';

export const policyOptionConverter: TConvertor<TOptionTypeUi, TPolicyOptionType> = {
  fromDb: (data) => {
    return {
      value: data.resourceId || '',
      label: data.name || '',
    };
  },
  toDb: (data) => {
    return {
      name: data.value,
    };
  },
};

export const getPolicyListConvertor: TFetchConverter<TOptionTypeUi[], TPolicyOptionType[]> = {
  fromDb: (data) => data?.map((item) => policyOptionConverter.fromDb(item)) || [],
};

export const docHtmlConvertor: TFetchConverter<TDocHtmlUI, string> = {
  fromDb: (data) => {
    const content = {
      html: /<body.*?>([\s\S]*)<\/body>/.exec(data)?.[1] || '',
      css: /<style.*?>([\s\S]*)<\/style>/.exec(data)?.[1] || '',
    };

    // To convert wierd character to bullet points
    content.html = content.html.replaceAll('​&nbsp;', '&#9679;');
    return content;
  },
};
