import { EVisitationUrl } from 'antd/data/urls/visitations.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { TVisitationAnimalDB, TVisitationAnimalUI } from 'antd/data/types/visitation.types';
import { getVisitationAnimalConverter } from 'antd/data/converters/visitation.converter';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EVisitationUrl.VISITATIONS}`;

export const visitationApi = authSplitApi('visitationApi', ['visitationAnimal']).injectEndpoints({
  endpoints: (build) => ({
    getVisitationAnimal: build.query<TVisitationAnimalUI, string>({
      query(id) {
        return {
          url: `${baseUrl}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['visitationAnimalDetails'],
      transformResponse: (data: TVisitationAnimalDB) => getVisitationAnimalConverter.fromDb(data),
    }),
    dontApproveAnimalVisitation: build.mutation<void, { animalVisitationId: string; reason: string }>({
      query({ animalVisitationId, reason }) {
        return {
          url: `${baseUrl}/${EVisitationUrl.REJECT}`,
          method: 'PATCH',
          body: { animalVisitationId, reason },
        };
      },
      invalidatesTags: ['visitationAnimalDetails'],
    }),

    approveAnimalVisitation: build.mutation<void, { animalVisitationId: string }>({
      query({ animalVisitationId }) {
        return {
          url: `${baseUrl}/${EVisitationUrl.APPROVE}`,
          method: 'PATCH',
          body: { animalVisitationId },
        };
      },
      invalidatesTags: ['visitationAnimalDetails'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetVisitationAnimalQuery,
  useDontApproveAnimalVisitationMutation,
  useApproveAnimalVisitationMutation,
} = visitationApi;
