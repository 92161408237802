import { TAuthConverter } from '../types/converter.types';
import {
  TIntegrationDataUI,
  TIntegrationExecutionResultProps,
  TIntegrationResultProps,
  TIntegrationResultPropsUI,
} from '../types/integration.types';

export const uploadCSVPreviewConverter: TAuthConverter<
  TIntegrationDataUI,
  FormData,
  TIntegrationResultPropsUI,
  TIntegrationResultProps
> = {
  fromDb: (data) => ({
    movedInResidencies: data.moveInResidencies.numberOfResidencies,
    movedInTotalAnimals: data.moveInResidencies.numberOfAnimals + data.numberOfNewAnimals,
    movedInAnimals: data.moveInResidencies.numberOfAnimals,
    numberOfNewAnimals: data.numberOfNewAnimals,
    movedOutTotalResidencies:
      data.moveOutResidencies.numberOfResidencies + data.terminatingMissingPropertyResidences.numberOfResidencies,
    movedOutResidencies: data.moveOutResidencies.numberOfResidencies,
    movedOutAnimals: data.moveOutResidencies.numberOfAnimals,
    movedOutResidenciesFromMissingProperty: data.terminatingMissingPropertyResidences.numberOfResidencies,
    numberOfMissingProperties: data.terminatingMissingPropertyResidences.numberOfProperties,
    numberOfMatchedProperties: data.numberOfMatchedProperties,
    numberOfSkippedResidencies: data.skippedNotFoundPropertyResidences.numberOfResidencies,
    numberOfSkippedProperties: data.skippedNotFoundPropertyResidences.numberOfProperties,
  }),
  toDb: (data) => {
    const formData = new FormData();
    formData.append('type', data.type.value);
    formData.append('policyId', data.policy.value);
    formData.append('configuration.shouldConsiderMoveOut', data.shouldConsiderMoveOut);
    if (data.shouldConsiderMissingProperties) {
      formData.append('configuration.shouldConsiderMissingProperties', data.shouldConsiderMissingProperties);
    }
    data.csvFile.forEach((item) => {
      formData.append('csvFile', new Blob([item], { type: 'application/json' }));
    });
    return formData;
  },
};

export const uploadCSVExecuteConverter: TAuthConverter<
  TIntegrationDataUI,
  FormData,
  TIntegrationExecutionResultProps,
  TIntegrationExecutionResultProps
> = {
  fromDb: (data) => ({
    matchedPropertiesMoveInResidencies: data.matchedPropertiesMoveInResidencies,
    matchedPropertiesMoveOutResidencies: data.matchedPropertiesMoveOutResidencies,
    terminatingMissingPropertyResidences: data.terminatingMissingPropertyResidences,
  }),
  toDb: (data) => {
    const formData = new FormData();
    formData.append('type', data.type.value);
    formData.append('policyId', data.policy.value);
    formData.append('configuration.shouldConsiderMoveOut', data.shouldConsiderMoveOut);
    if (data.shouldConsiderMissingProperties) {
      formData.append('configuration.shouldConsiderMissingProperties', data.shouldConsiderMissingProperties);
    }
    data.csvFile.forEach((item) => {
      formData.append('csvFile', new Blob([item], { type: 'application/json' }));
    });
    return formData;
  },
};
