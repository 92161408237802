import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

export const authSplitApi = (reducerPath: string, tagTypes?: string[], oldBackEnd?: boolean) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: oldBackEnd ? `${process.env.REACT_APP_HOSTNAME}/api/v1/` : process.env.REACT_APP_HOSTNAME_V2,
      prepareHeaders: async (headers) => {
        const session = await Auth.currentSession();
        if (session) {
          headers.set('authorization', `Bearer ${session.getIdToken().getJwtToken()}`);
        }
        return headers;
      },
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const emptySplitApi = (reducerPath: string) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_HOSTNAME_V2,
    }),
    endpoints: () => ({}),
  });
