import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EPropertyUrl } from 'antd/data/urls/property.url';
import {
  getUnitsCoverter,
  propertiesGetConverter,
  propertiesTemplateTypeConverter,
  propertyCsvUploadConverter,
  propertyTypeConverter,
} from 'antd/data/converters/property.converters';
import {
  EPropertiesTemplateTypeUI,
  TPropertiesCSVUploadResultDB,
  TPropertiesCSVUploadResultUI,
  TPropertiesUploadUI,
  TPropertyDB,
  TPropertyTypeDB,
  TPropertyTypeUI,
  TPropertyUI,
  TUnitDB,
  TUnitUI,
} from 'antd/data/types/property.types';

const URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}`;
export const propertyApi = authSplitApi('propertyApi', ['propertiesSearch', 'units', 'propertyTypes']).injectEndpoints({
  endpoints: (build) => ({
    getPropertiesSearch: build.query<TPropertyUI[], { [key: string]: string | undefined } | null | void>({
      query(params) {
        return {
          url: `${URL}/${EPropertyUrl.SEARCH}`,
          method: 'GET',
          params: { ...params },
        };
      },
      transformResponse: (data: TPropertyDB[]) => data?.map((item) => propertiesGetConverter.fromDb(item)) || [],
      providesTags: ['propertiesSearch'], // for caching and update cache
    }),
    getUnits: build.query<TUnitUI[], { propertyId: string }>({
      query(params) {
        return {
          url: `${URL}/${EPropertyUrl.UNITS}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TUnitDB[]) => data?.map((item) => getUnitsCoverter.fromDb(item)) || [],
      providesTags: ['units'],
    }),
    getPropertyTypes: build.query<TPropertyTypeUI[], string>({
      query() {
        return {
          url: `${URL}/${EPropertyUrl.TYPES}`,
          method: 'GET',
        };
      },
      providesTags: ['propertyTypes'],
      transformResponse: (data: TPropertyTypeDB[]) => data.map((item) => propertyTypeConverter.fromDb(item)),
    }),
    uploadPropertiesCSV: build.mutation<TPropertiesCSVUploadResultUI, TPropertiesUploadUI>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.UPLOAD}`,
          method: 'POST',
          body: propertyCsvUploadConverter.toDb(body),
        };
      },
      transformResponse: (data: TPropertiesCSVUploadResultDB) => propertyCsvUploadConverter.fromDb(data),
    }),
    previewCSVUploadResult: build.mutation<TPropertiesCSVUploadResultUI, TPropertiesUploadUI>({
      query(body) {
        return {
          url: `${URL}/${EPropertyUrl.PREVIEW}`,
          method: 'POST',
          body: propertyCsvUploadConverter.toDb(body),
        };
      },
      transformResponse: (data: TPropertiesCSVUploadResultDB) => propertyCsvUploadConverter.fromDb(data),
    }),
    getCSVTemplate: build.query<{ url: string }, { type: EPropertiesTemplateTypeUI }>({
      query(params) {
        return {
          url: `${URL}/${EPropertyUrl.TEMPLATE}`,
          method: 'GET',
          params: { ...params, type: propertiesTemplateTypeConverter.toDb(params.type) },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPropertiesSearchQuery,
  useGetUnitsQuery,
  useGetPropertyTypesQuery,
  useGetCSVTemplateQuery,
  useUploadPropertiesCSVMutation,
  usePreviewCSVUploadResultMutation,
} = propertyApi;
