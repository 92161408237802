import {validationRules} from "antd/components/Form";
import {TSetPasswordFormData} from "./SetPasswordForm.types";


export const setPasswordFormData: TSetPasswordFormData = {
    password: {
        name: 'password',
        label: 'New password (Min. of 6 characters)',
        rules: [
            ...validationRules.password,
            validationRules.required('password'),
            validationRules.withoutSpace('Password'),
        ],
        placeholder: 'Enter password',
    },
    passwordConfirm: {
        name: 'passwordConfirm',
        label: 'Repeat the password',
        rules: [
            ...validationRules.passwordConfirm,
            validationRules.required('confirm password'),
        ],
        placeholder: 'Repeat the password',
    },
}