import React, {FC, useEffect, useRef} from 'react';
import {Col, Row} from 'antd/components/Grid';
import AuthFormsWrapper from "antd/componentsShared/AuthFormsWrapper";
import Alert from "antd/components/Alert";
import Form, {FormItem} from "antd/components/Form";
import {TextField} from "antd/components/TextField";
import useForm from "antd/hooks/useForm";
import {TSignInAmplifyUI} from "antd/data/types/auth.types";
import {useResetPasswordCasesMutation, useSignInMutation} from "antd/redux/apiSlices/auth.slice";
import {RoutesNames} from "routes/RoutesNames";
import get from "lodash/get";
import Button from "antd/components/Button";
import {Paragraph, Text, Title} from "antd/components/Typography";
import {Link} from "react-router-dom";
import {Colors} from "core/CssVariables";
import {useStateSafely} from "antd/hooks/useStateSafely";
import {EErrorCode, TAmplifyErrorPayload} from "antd/data/types/amplify.types";
import {loginFields} from "./Login.data";
import SetPasswordForm from "../SetPasswordForm";

const {email, password} = loginFields;

const Login: FC = () => {
    const {form} = useForm<TSignInAmplifyUI>();
    const [error, setError] = useStateSafely<string>('');
    const alertRef = useRef<HTMLDivElement | null>(null);
    const [signIn, {isLoading, data}] = useSignInMutation();
    const [resetPassword] = useResetPasswordCasesMutation()

    const sendInvitationHandler = async (email: string) => {
        try {
            await resetPassword({email});
        } catch (err) {
            const error = err as TAmplifyErrorPayload;
            const message = get(error, 'data.message');
            setError(message);
        }
    }
    const onSubmit = async (values: TSignInAmplifyUI) => {
        try {
            await signIn(values).unwrap();
        } catch (err) {
            const error = err as TAmplifyErrorPayload;
            const code = get(error, 'data.code');
            const message = get(error, 'data.message');
            if (
                code === EErrorCode.NOT_AUTHORIZED_EXCEPTION &&
                message?.includes('expired')
            ) {
                setError(`Temporary password has expired. New password has been sent to ${values.email}.`);
                await sendInvitationHandler(values.email);

            } else {
                setError(message);
            }
        }
    }
    useEffect(() => {
        if (error) {
            alertRef.current?.scrollIntoView({block: 'end'})
        }
    }, [error])
    if (data?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return <SetPasswordForm email={form.getFieldValue('email')} temporaryPassword={form.getFieldValue('password')}/>
    }
    return (
        <Row justify='center'>
            <AuthFormsWrapper title='Welcome back!'>
                <Title fontLevel={5} className='mb-6'>Enter your credentials to login</Title>
                <Form form={form} onFinish={onSubmit} requiredMark={false} scrollToFirstError>
                    <Row>
                        <Col span={24} ref={alertRef}>
                            <Alert type='error' message={error}/>
                        </Col>
                        <Col span={24}>
                            <FormItem name={email.name} rules={email.rules} label={email.label}>
                                <TextField placeholder={email.placeholder}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={password.name} rules={password.rules} label={password.label}>
                                <TextField placeholder={password.placeholder} type='password'/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <Button htmlType='submit' loading={isLoading}>Sign in</Button>
                            <Paragraph className='mt-2' fontLevel={5}>
                                Forgot password?{' '}
                                <Text color={Colors.DarkBlue} fontLevel={5} underline><Link
                                    to={RoutesNames.ResetPassword}>
                                    Reset it here
                                </Link>
                                </Text>
                            </Paragraph>
                            <Paragraph className='mt-2' fontLevel={5}>
                                Don&apos;t have an account?{' '}
                                <Text color={Colors.DarkBlue} fontLevel={5} underline> <Link to={RoutesNames.SignUp}>
                                    Sign up here
                                </Link>
                                </Text>
                            </Paragraph>
                        </Col>
                    </Row>
                </Form>
            </AuthFormsWrapper>
        </Row>
    );
};

export default Login
  