import React from 'react';
import {Text, Link} from "antd/components/Typography";
import {Colors} from "core/CssVariables";

const TermsAndConditions = () => {
    return (
        <div>
            <Text fontLevel={5}>
                I accept the{' '}
                <Link href='https://ourpetpolicy.com/terms-and-conditions/' color={Colors.DarkBlue} underline fontLevel={5}>
                    Terms and Conditions
                </Link>{' '}
                and <Link fontLevel={5} href='https://ourpetpolicy.com/privacy-policy/' color={Colors.DarkBlue} underline>Privacy Policy</Link>
            </Text>
        </div>
    );
};

export default TermsAndConditions;