import axios from 'axios';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';

axios.defaults.withCredentials = true;
const baseURL = `${process.env.REACT_APP_HOSTNAME}/api/v1/`;
const baseURLV2 = `${process.env.REACT_APP_HOSTNAME_V2}`;
const baseURLOld = `https://backend.prod.ourpetpolicy.com/api/v1`;

const instance = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
});

const isTokenExpired = (expiration) => new Date().getTime() / 1000 > expiration;

const configureAxios = async (config) => {
  const res = { ...config };
  const session = await Auth.currentSession();
  const { jwtToken, payload } = session.idToken;
  res.headers.Authorization = jwtToken ? `Bearer ${jwtToken}` : '';
  if (isTokenExpired(payload.exp || '')) {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    cognitoUser.refreshSession(get(session, 'refreshToken'), (err, userSession) => {
      const token = get(userSession, 'idToken.jwtToken', '');
      res.headers.Authorization = token ? `Bearer ${token}` : '';
    });
  }
  return res;
};

instance.interceptors.request.use(configureAxios);

const axiosUnauth = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
});

const axiosV2 = axios.create({
  baseURL: baseURLV2,
  withCredentials: false,
  headers: {
    'content-type': 'application/json',
  },
});

const axiosOld = axios.create({
  baseURL: baseURLOld,
  headers: {
    'content-type': 'application/json',
  },
});

const axiosDefault = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
});
axiosV2.interceptors.request.use(configureAxios);
axiosOld.interceptors.request.use(configureAxios);
axiosDefault.interceptors.request.use(configureAxios);

export { axiosUnauth, axiosV2, axiosOld, axiosDefault };

export default instance;

export const formDataCreator = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};
