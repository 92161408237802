import { formattedDate } from 'antd/helpers/utils';
import { TConvertor, TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  EAnimalResidencyStatusDB,
  EAnimalResidencyStatusUI,
  EPooCompaniesDB,
  EResidenceStatusDB,
  EResidenceStatusUi,
  EResidencyStatusDB,
  EResidencyStatusUI,
  EResidentHasAnimalDB,
  EResidentHasAnimalUI,
  TApplicantDetailsDB,
  TApplicantDetailsUI,
  TApplicantResidencyDB,
  TCollectDNA,
  TCollectDNAUi,
  TCompanyDB,
  TCompanyUi,
  TResidenceDB,
  TResidenceFilterDB,
  TResidenceFilterUI,
  TResidenceMoveInfo,
  TResidenceMoveInfoUI,
  TResidenceUI,
  TResidencyApplicationApplyDB,
  TResidencyApplicationApplyUI,
  TResidencyApplicationRejectDB,
  TResidencyApplicationRejectUI,
  TResidentAnimalDB,
  TResidentAnimalUI,
  TResidentDetailsDB,
  TResidentDetailsUI,
  TResidentMove,
  TResidentMoveUi,
  TResidentProfileDB,
  TResidentProfileUI,
  TResidentResidencyDB,
  TResidentResidencyUI,
  TResidentSearchDB,
  TResidentSearchUI,
  TUnCollectDNA,
  TUnCollectDNAUi,
} from '../types/residence.types';
import { animalTypeConverter } from './animals.converter';
import { policyDataConverter, statusChangeInfoConverter, verificationStatusConverter } from './general.converters';

export const residenceStatusConverter: TConvertor<EResidenceStatusUi, EResidenceStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidenceStatusDB]: EResidenceStatusUi } = {
      [EResidenceStatusDB.ACTIVE]: EResidenceStatusUi.TERMINATED,
      [EResidenceStatusDB.TERMINATED]: EResidenceStatusUi.TERMINATED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidenceStatusUi]: EResidenceStatusDB } = {
      [EResidenceStatusUi.ACTIVE]: EResidenceStatusDB.ACTIVE,
      [EResidenceStatusUi.TERMINATED]: EResidenceStatusDB.TERMINATED,
    };
    return dataMapper[data];
  },
};

export const residenceGetConverter: TFetchConverter<TResidenceUI, TResidenceDB> = {
  fromDb: (data) => {
    return {
      residenceId: data.residenceId,
      residentName: data.residentName,
      unit: {
        id: data.unit.id,
        number: data.unit.number,
      },
      property: {
        id: data.property.propertyId,
        name: data.property.name,
      },
      status: residenceStatusConverter.fromDb(data.status),
    };
  },
};

export const residenceFilterConverter: TFetchConverter<TResidenceFilterUI, TResidenceFilterDB> = {
  fromDb: (data) => {
    return {
      residenceProfile: `${data.residentName} ${data.propertyName} ${data.unitNumber}`,
      residenceIds: data.residenceIds,
    };
  },
};

export const residenceMoveConverter: TFetchConverter<TResidenceMoveInfoUI, TResidenceMoveInfo> = {
  fromDb: (data) => {
    return {
      isPolicyChanging: data.isPolicyChanging,
    };
  },
};

export const ResidentMoveConverter: TCreateConverter<TResidentMoveUi, TResidentMove> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      propertyId: data.property.value,
      unitNumber: data.unit.label,
    };
  },
};

export const residentsSearchGetConverter: TFetchConverter<TResidentSearchUI, TResidentSearchDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      value: data.residentId,
    };
  },
};

export const companyNameConverter: TConvertor<TCompanyUi, TCompanyDB> = {
  toDb: (data) => {
    const isOther =
      data.value && ![EPooCompaniesDB.POO_PRINTS, EPooCompaniesDB.MR_DOG_POOP].includes(data.value as EPooCompaniesDB);
    return {
      companyOption: isOther ? EPooCompaniesDB.OTHER : data.value,
      companyCustomName: isOther ? data.value : null,
    };
  },
  fromDb: (data) => {
    const isOther = data.companyOption === EPooCompaniesDB.OTHER;
    return {
      value: (isOther ? data.companyCustomName : data.companyOption) || null,
      label: (isOther ? data.companyCustomName : data.companyOption) || null,
    };
  },
};

export const collectDnaConverter: TCreateConverter<TCollectDNAUi, TCollectDNA> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      animalResidencyId: data.animalResidencyId,
      ...(data.company && {
        company: companyNameConverter.toDb(data.company),
      }),
      ...(data.dnaNumber && {
        dnaNumber: data.dnaNumber,
      }),
    };
  },
};

export const unCollectDnaConverter: TCreateConverter<TUnCollectDNAUi, TUnCollectDNA> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      animalResidencyId: data.animalResidencyId,
    };
  },
};

export const animalResidencyStatusConverter: TConvertor<EAnimalResidencyStatusUI, EAnimalResidencyStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalResidencyStatusDB]: EAnimalResidencyStatusUI } = {
      [EAnimalResidencyStatusDB.FORMER_RESIDENT]: EAnimalResidencyStatusUI.FORMER_RESIDENT,
      [EAnimalResidencyStatusDB.RESIDENT]: EAnimalResidencyStatusUI.RESIDENT,
      [EAnimalResidencyStatusDB.APPLICANT]: EAnimalResidencyStatusUI.APPLICANT,
      [EAnimalResidencyStatusDB.ARCHIVED]: EAnimalResidencyStatusUI.ARCHIVED,
      [EAnimalResidencyStatusDB.DENIED]: EAnimalResidencyStatusUI.DENIED,
      [EAnimalResidencyStatusDB.APPROVED]: EAnimalResidencyStatusUI.APPROVED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EAnimalResidencyStatusUI]: EAnimalResidencyStatusDB } = {
      [EAnimalResidencyStatusUI.FORMER_RESIDENT]: EAnimalResidencyStatusDB.FORMER_RESIDENT,
      [EAnimalResidencyStatusUI.RESIDENT]: EAnimalResidencyStatusDB.RESIDENT,
      [EAnimalResidencyStatusUI.APPLICANT]: EAnimalResidencyStatusDB.APPLICANT,
      [EAnimalResidencyStatusUI.ARCHIVED]: EAnimalResidencyStatusDB.ARCHIVED,
      [EAnimalResidencyStatusUI.DENIED]: EAnimalResidencyStatusDB.DENIED,
      [EAnimalResidencyStatusUI.APPROVED]: EAnimalResidencyStatusDB.APPROVED,
    };
    return dataMapper[data];
  },
};

export const tenantResidencyStatusConverter: TConvertor<EResidencyStatusUI, EResidencyStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidencyStatusDB]: EResidencyStatusUI } = {
      [EResidencyStatusDB.FORMER_RESIDENT]: EResidencyStatusUI.FORMER_RESIDENT,
      [EResidencyStatusDB.RESIDENT]: EResidencyStatusUI.RESIDENT,
      [EResidencyStatusDB.APPLICANT]: EResidencyStatusUI.APPLICANT,
      [EResidencyStatusDB.DENIED]: EResidencyStatusUI.DENIED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidencyStatusUI]: EResidencyStatusDB } = {
      [EResidencyStatusUI.FORMER_RESIDENT]: EResidencyStatusDB.FORMER_RESIDENT,
      [EResidencyStatusUI.RESIDENT]: EResidencyStatusDB.RESIDENT,
      [EResidencyStatusUI.APPLICANT]: EResidencyStatusDB.APPLICANT,
      [EResidencyStatusUI.DENIED]: EResidencyStatusDB.DENIED,
    };
    return dataMapper[data];
  },
};

export const residentHasAnimalConverter: TConvertor<EResidentHasAnimalUI, EResidentHasAnimalDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidentHasAnimalDB]: EResidentHasAnimalUI } = {
      [EResidentHasAnimalDB.HAS_ANIMAL]: EResidentHasAnimalUI.HAS_ANIMAL,
      [EResidentHasAnimalDB.HAS_NOT_ANIMAL]: EResidentHasAnimalUI.HAS_NOT_ANIMAL,
      [EResidentHasAnimalDB.NOT_SPECIFIED]: EResidentHasAnimalUI.NOT_SPECIFIED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidentHasAnimalUI]: EResidentHasAnimalDB } = {
      [EResidentHasAnimalUI.HAS_ANIMAL]: EResidentHasAnimalDB.HAS_ANIMAL,
      [EResidentHasAnimalUI.HAS_NOT_ANIMAL]: EResidentHasAnimalDB.HAS_NOT_ANIMAL,
      [EResidentHasAnimalUI.NOT_SPECIFIED]: EResidentHasAnimalDB.NOT_SPECIFIED,
    };
    return dataMapper[data];
  },
};

export const residentProfileDataConverter: TFetchConverter<TResidentProfileUI, TResidentProfileDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      profilePicture: data?.profilePicture || '',
      email: data?.email || '',
      phoneNumber: data?.phoneNumber || '',
    };
  },
};

export const residentResidencyDataConverter: TFetchConverter<TResidentResidencyUI, TResidentResidencyDB> = {
  fromDb: (data) => {
    return {
      id: data.residenceId,
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      unitName: data.unitName,
      moveInDate: formattedDate(data.moveInDate, 'MM/DD/yyyy'),
      leaseToDate: formattedDate(data.leaseToDate, 'MM/DD/yyyy'),
    };
  },
};

export const applicantResidencyDataConverter: TFetchConverter<TResidentResidencyUI, TApplicantResidencyDB> = {
  fromDb: (data) => {
    return {
      id: data.residencyApplicationId,
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      unitName: data.unitName,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate, 'MM/DD/yyyy') : null,
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate, 'MM/DD/yyyy') : null,
    };
  },
};

export const residentAnimalDataConverter: TFetchConverter<TResidentAnimalUI, TResidentAnimalDB> = {
  fromDb: (data) => {
    return {
      type: animalTypeConverter.fromDb(data.type),
      name: data.name,
      profilePicture: data?.profilePicture || '',
      species: data?.species || '',
      breed: data?.breed || '',
      weight: data.weight ? `${data.weight.value} ${data.weight.unit}` : '',
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      residencyStatus: animalResidencyStatusConverter.fromDb(data.residencyStatus),
      id: data.id,
    };
  },
};

export const residentDetailsConverter: TFetchConverter<TResidentDetailsUI, TResidentDetailsDB> = {
  fromDb: (data) => {
    return {
      residencyStatus: tenantResidencyStatusConverter.fromDb(data.residencyStatus),
      residentId: data.residentId,
      residentProfile: residentProfileDataConverter.fromDb({
        profilePicture: data.profilePicture,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      }),
      residentResidency: residentResidencyDataConverter.fromDb({
        residenceId: data.residenceId,
        propertyId: data.propertyId,
        propertyName: data.propertyName,
        unitName: data.unitName,
        moveInDate: data.moveInDate,
        leaseToDate: data.leaseToDate,
      }),
      policy: policyDataConverter.fromDb({
        policyId: data.policyId,
        policyName: data.policyName,
        policyState: data.policyState,
      }),
      petStatus: residentHasAnimalConverter.fromDb(data.petStatus),
      roommatePetStatus: residentHasAnimalConverter.fromDb(data.roommatePetStatus),
      animals: (data.animals || []).map((item) => residentAnimalDataConverter.fromDb(item)),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
    };
  },
};

export const applicantDetailsConverter: TFetchConverter<TApplicantDetailsUI, TApplicantDetailsDB> = {
  fromDb: (data) => {
    return {
      residencyStatus: tenantResidencyStatusConverter.fromDb(data.residencyStatus),
      residentId: data.residentId,
      residencyApplicationId: data.residencyApplicationId,
      residentProfile: residentProfileDataConverter.fromDb({
        profilePicture: data.profilePicture,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      }),
      residentResidency: applicantResidencyDataConverter.fromDb({
        residencyApplicationId: data.residencyApplicationId,
        propertyId: data.propertyId,
        propertyName: data.propertyName,
        unitName: data.unitName,
        moveInDate: data.moveInDate,
        leaseToDate: data.leaseToDate,
      }),
      policy: policyDataConverter.fromDb({
        policyId: data.policyId,
        policyName: data.policyName,
        policyState: data.policyState,
      }),
      petStatus: residentHasAnimalConverter.fromDb(data.petStatus),
      roommatePetStatus: residentHasAnimalConverter.fromDb(data.roommatePetStatus),
      animals: (data.animals || []).map((item) => residentAnimalDataConverter.fromDb(item)),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
    };
  },
};

export const residencyApplicationApproveConverter: TCreateConverter<
  TResidencyApplicationApplyUI,
  TResidencyApplicationApplyDB
> = {
  toDb: (data) => {
    return {
      residencyApplicationId: data.id,
      propertyId: typeof data.property.value === 'string' ? data.property.value : data.property.value.value,
      unitName: data.unit.label,
      leaseToDate: formattedDate(data.endTime, 'YYYY-MM-DD'),
      moveInDate: formattedDate(data.startTime, 'YYYY-MM-DD'),
    };
  },
};

export const residencyApplicationRejectConverter: TCreateConverter<
  TResidencyApplicationRejectUI,
  TResidencyApplicationRejectDB
> = {
  toDb: (data) => {
    return {
      residencyApplicationId: data.id,
      reason: data.reason,
    };
  },
};
