import { TConvertor, TCreateConverter, TFetchConverter } from 'antd/data/types/converter.types';
import {
  EAnimalStatusDB,
  EAnimalStatusUI,
  EAnimalTypes,
  EAnimalTypesUI,
  EAuthorizationStatus,
  EAuthorizationStatusUi,
  EResidenceStatusDB,
  EResidenceStatusUI,
  TAnimalApplication,
  TAnimalApplicationUi,
  TAnimalBasicInfo,
  TAnimalBasicInfoUi,
  TAnimalDB,
  TAnimalDetail,
  TAnimalDetailUi,
  TAnimalResidenceDB,
  TAnimalResidenceUI,
  TAnimalTerminateDB,
  TAnimalTerminateUI,
  TAnimalUI,
  TAnimalUnitDB,
  TAnimalUnitUI,
  TAnimalWeightDB,
  TAnimalWeightUI,
  TInfoDNA,
  TDnaInfoDB,
  TInfoDNAUi,
  TDnaInfoUI,
  TProperty,
  TPropertyDB,
  TPropertyDataUi,
  TResidentProfileDB,
  TResidentProfileUI,
  TUnit,
  TUnitUi,
  TAnimalOwnerInfoUI,
  TAnimalOwnerInfoDB,
  TApplicantAnimalUI,
  TApplicantAnimalDB,
  TDataOverviewUI,
  TDataOverviewDB,
  EDocsOverviewStatusUI,
  EDocsOverviewStatusDB,
  EPictureOverviewStatusDB,
  EPictureOverviewStatusUI,
  EVetRecordOverviewStatusDB,
  EVetRecordOverviewStatusUI,
  TConvertedAnimalDB,
  TConvertedAnimalUI,
  TAnimalStatisticDB,
  TAnimalStatisticUI,
  TAnimalsStatisticsUI,
  TAnimalsStatisticsDB,
  TPreventedAnimalDB,
  TPreventedAnimalUI,
  EPreventedAnimalStatusDB,
  EPreventedAnimalStatusUI,
  TChangerStatusRolesDB,
  TChangerStatusRolesUI,
} from 'antd/data/types/animals.types';
import { formattedDate } from 'antd/helpers/utils';
import {
  applicationStatusConvertor,
  genderEnumConvertor,
  getFirstLetters,
  policyDataConverter,
  predefinedOptionConverter,
  propertyDataConverter,
  reproductiveConvertor,
  selectOptionConverter,
  statusChangeInfoConverter,
  unitDataConverter,
  verificationStatusConverter,
} from './general.converters';
import { EVetRecordStatus, EVetRecordStatusUi, TAnimalVetRecord, TAnimalVetRecordUi } from '../types/vetRecord.types';
import { TExpirationDateType, TFileDataType } from '../types/general.types';
import { docPacketStatusConverter, getHPDocPacketsConvertor } from './docPacket.convertors';
import { TSADocPacketGet, TSADocPacketGetUI } from '../types/animalTraining.types';
import { trainerInfoConvertor } from './animalTraining.convertors';
import {
  animalResidencyStatusConverter,
  companyNameConverter,
  tenantResidencyStatusConverter,
} from './residence.converters';

export const animalTypeConverter: TConvertor<EAnimalTypesUI, EAnimalTypes> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalTypes]: EAnimalTypesUI } = {
      [EAnimalTypes.ESA]: EAnimalTypesUI.ESA,
      [EAnimalTypes.PET]: EAnimalTypesUI.PET,
      [EAnimalTypes.SA]: EAnimalTypesUI.SA,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EAnimalTypesUI]: EAnimalTypes } = {
      [EAnimalTypesUI.ESA]: EAnimalTypes.ESA,
      [EAnimalTypesUI.PET]: EAnimalTypes.PET,
      [EAnimalTypesUI.SA]: EAnimalTypes.SA,
    };
    return dataMapper[data];
  },
};

export const animalStatusConverter: TConvertor<EAnimalStatusUI, EAnimalStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalStatusDB]: EAnimalStatusUI } = {
      [EAnimalStatusDB.ACTIVE]: EAnimalStatusUI.ACTIVE,
      [EAnimalStatusDB.TERMINATED]: EAnimalStatusUI.TERMINATED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EAnimalStatusUI]: EAnimalStatusDB } = {
      [EAnimalStatusUI.ACTIVE]: EAnimalStatusDB.ACTIVE,
      [EAnimalStatusUI.TERMINATED]: EAnimalStatusDB.TERMINATED,
    };

    return dataMapper[data];
  },
};

export const animalResidenceStatusConverter: TConvertor<EResidenceStatusUI, EResidenceStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalStatusDB]: EResidenceStatusUI } = {
      [EResidenceStatusDB.ACTIVE]: EResidenceStatusUI.ACTIVE,
      [EResidenceStatusDB.TERMINATED]: EResidenceStatusUI.TERMINATED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidenceStatusUI]: EResidenceStatusDB } = {
      [EResidenceStatusUI.ACTIVE]: EResidenceStatusDB.ACTIVE,
      [EResidenceStatusUI.TERMINATED]: EResidenceStatusDB.TERMINATED,
    };

    return dataMapper[data];
  },
};

export const animalResidenceProfileConverter: TFetchConverter<TResidentProfileUI, TResidentProfileDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      residentId: data.residentId,
    };
  },
};

export const animalPropertyConverter: TFetchConverter<TPropertyDataUi, TPropertyDB> = {
  fromDb: (data) => {
    return {
      propertyId: data.propertyId,
      name: data.name,
    };
  },
};

export const animalUnitConverter: TFetchConverter<TAnimalUnitUI, TAnimalUnitDB> = {
  fromDb: (data) => {
    return {
      number: data.number,
      property: animalPropertyConverter.fromDb(data.property),
    };
  },
};

export const dnaInfoConverter: TFetchConverter<TDnaInfoUI, TDnaInfoDB> = {
  fromDb: (data) => {
    return {
      company: data.company || '',
      companyCustomName: data.companyCustomName || '',
      dnaNumber: data.dnaNumber || '',
      isCollected: data.isCollected,
    };
  },
};

export const animalResidenceConverter: TFetchConverter<TAnimalResidenceUI, TAnimalResidenceDB> = {
  fromDb: (data) => {
    return {
      dnaEnabled: data.dnaEnabled,
      residenceId: data.residenceId,
      residentProfile: animalResidenceProfileConverter.fromDb(data.residentProfile),
      status: data.status ? animalResidenceStatusConverter.fromDb(data.status) : null,
      unit: animalUnitConverter.fromDb(data.unit),
    };
  },
};

export const animalWeightConverter: TFetchConverter<TAnimalWeightUI, TAnimalWeightDB> = {
  fromDb: (data) => {
    return {
      unit: data.unit,
      value: data.value,
    };
  },
};

export const animalTypesConvertor: TConvertor<EAnimalTypesUI, EAnimalTypes> = {
  fromDb: (data) => {
    return EAnimalTypesUI[EAnimalTypes[data] as keyof typeof EAnimalTypesUI];
  },
  toDb: (data) => {
    return EAnimalTypes[EAnimalTypesUI[data] as keyof typeof EAnimalTypes];
  },
};
export const statusChangerRoleConvertor: TConvertor<TChangerStatusRolesUI, TChangerStatusRolesDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in TChangerStatusRolesDB]: TChangerStatusRolesUI } = {
      [TChangerStatusRolesDB.ADMIN]: TChangerStatusRolesUI.ADMIN,
      [TChangerStatusRolesDB.LANDLORD]: TChangerStatusRolesUI.LANDLORD,
      [TChangerStatusRolesDB.PROPERTY_MANAGER]: TChangerStatusRolesUI.PROPERTY_MANAGER,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in TChangerStatusRolesUI]: TChangerStatusRolesDB } = {
      [TChangerStatusRolesUI.ADMIN]: TChangerStatusRolesDB.ADMIN,
      [TChangerStatusRolesUI.LANDLORD]: TChangerStatusRolesDB.LANDLORD,
      [TChangerStatusRolesUI.PROPERTY_MANAGER]: TChangerStatusRolesDB.PROPERTY_MANAGER,
    };
    return dataMapper[data];
  },
};

export const animalConverter: TFetchConverter<TAnimalUI, TAnimalDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      name: data.name,
      firstLetters: getFirstLetters(data.name),
      profilePicture: data.profilePicture,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      status: animalStatusConverter.fromDb(data.status),
      type: animalTypeConverter.fromDb(data.type),
      animalVerificationStatus: verificationStatusConverter.fromDb(data.animalVerificationStatus),
      birthday: data.birthday ? formattedDate(`${data.birthday}Z`, 'MM/DD/YYYY') : '',
      deleted: data.deleted,
      dnaInfo: data.dnaInfo ? dnaInfoConverter.fromDb(data.dnaInfo) : null,
      breed: data.breed ? selectOptionConverter.fromDb(data.breed) : null,
      species: data.species ? selectOptionConverter.fromDb(data.species) : null,
      primaryColor: data.primaryColor ? predefinedOptionConverter.fromDb(data.primaryColor) : null,
      weight: data.weight ? animalWeightConverter.fromDb(data.weight) : null,
      residence: data.residence ? animalResidenceConverter.fromDb(data.residence) : null,
      moveOutDate: data.moveOutDate ? formattedDate(`${data.moveOutDate}Z`, 'MM/DD/YYYY') : '',
      moveOutReason: data.moveOutReason || '',
    };
  },
};

export const animalBasicInfoConvertor: TFetchConverter<TAnimalBasicInfoUi, TAnimalBasicInfo> = {
  fromDb: (data) => {
    return {
      birthDate: data.birthDate ? formattedDate(data.birthDate, 'MM/DD/YYYY') : null,
      breed: data.breed,
      gender: genderEnumConvertor.fromDb(data.gender),
      id: data.id,
      isHouseTrained: data.isHouseTrained,
      reproduction: data.isReproductive !== null ? reproductiveConvertor.fromDb(data.gender) : null,
      name: data.name,
      photoGallery: data.photoGallery,
      primaryColor: data.primaryColor || '',
      profilePicture: data.profilePicture,
      secondaryColor1: data.secondaryColor1 || '',
      secondaryColor2: data.secondaryColor2 || '',
      species: data.species,
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      type: animalTypesConvertor.fromDb(data.type),
      weight: data.weight ? animalWeightConverter.fromDb(data.weight) : null,
    };
  },
};

export const authorizationStatusConverter: TConvertor<EAuthorizationStatusUi, EAuthorizationStatus> = {
  fromDb: (data) => {
    const statuses: {
      [key in EAuthorizationStatus]: EAuthorizationStatusUi;
    } = {
      [EAuthorizationStatus.AUTHORIZED]: EAuthorizationStatusUi.AUTHORIZED,
    };
    return statuses[data];
  },
  toDb: (data) => {
    const statuses: {
      [key in EAuthorizationStatusUi]: EAuthorizationStatus;
    } = {
      [EAuthorizationStatusUi.AUTHORIZED]: EAuthorizationStatus.AUTHORIZED,
    };
    return statuses[data];
  },
};

export const propertyConvertor: TFetchConverter<TProperty, TPropertyDataUi> = {
  fromDb: (data) => {
    return {
      propertyId: data.propertyId,
      name: data.name,
    };
  },
};

export const unitConvertor: TFetchConverter<TUnit, TUnitUi> = {
  fromDb: (data) => {
    return {
      number: data.number,
      unitId: data.unitId,
      property: propertyConvertor.fromDb(data.property),
    };
  },
};

export const animalOwnerConvertor: TFetchConverter<TAnimalOwnerInfoUI, TAnimalOwnerInfoDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      email: data.email,
      moveInDate: formattedDate(data.moveInDate),
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      name: data.name,
      phoneNumber: data.phoneNumber,
      policy: policyDataConverter.fromDb({
        policyId: data.policyId,
        policyName: data.policyName,
        policyState: data.policyState,
      }),
      profilePicture: data.profilePicture,
      property: propertyDataConverter.fromDb({ propertyId: data.propertyId, propertyName: data.propertyName }),
      residencyStatus: tenantResidencyStatusConverter.fromDb(data.residencyStatus),
      unit: unitDataConverter.fromDb({ unitId: data.unitId, unitName: data.unitName }),
    };
  },
};

export const animalApplicationConvertor: TFetchConverter<TAnimalApplicationUi, TAnimalApplication> = {
  fromDb: (data) => {
    return {
      applicationStatus: applicationStatusConvertor.fromDb(data.applicationStatus),
      id: data.id,
      leaseStartDate: formattedDate(data.leaseStartDate),
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      policyId: data.policyId,
      propertyManagerName: data.propertyManagerName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
    };
  },
};

export const dnaInfoConvertor: TFetchConverter<TInfoDNAUi, TInfoDNA> = {
  fromDb: (data) => {
    return {
      isDnaRequired: data.isDnaRequired,
      isDnaCollected: data.isDnaCollected,
      dnaNumber: data.dnaNumber,
      dnaCompany: data.dnaCompany ? companyNameConverter.fromDb(data.dnaCompany) : null,
    };
  },
};

export const vetRecordStatusConverter: TConvertor<EVetRecordStatusUi, EVetRecordStatus> = {
  fromDb: (data) => {
    const statuses: {
      [key in EVetRecordStatus]: EVetRecordStatusUi;
    } = {
      [EVetRecordStatus.NONE]: EVetRecordStatusUi.NONE,
      [EVetRecordStatus.VERIFIED]: EVetRecordStatusUi.VERIFIED,
    };
    return statuses[data];
  },
  toDb: (data) => EVetRecordStatus[EVetRecordStatusUi[data] as keyof typeof EVetRecordStatus],
};

export const animalVetRecordExpirationConvertor: TFetchConverter<TExpirationDateType, TExpirationDateType> = {
  fromDb: (data) => {
    return {
      date: data.date,
      expirable: data.expirable,
      expired: data.expired,
    };
  },
};

export const documentFilesConvertor: TFetchConverter<TFileDataType, TFileDataType> = {
  fromDb: (data) => {
    return {
      url: data.url,
      name: data.name,
      documentFileId: data.documentFileId,
      createdDate: data.createdDate,
      createdBy: data.createdBy,
    };
  },
};

export const animalVetRecordConvertor: TFetchConverter<TAnimalVetRecordUi, TAnimalVetRecord> = {
  fromDb: (data) => {
    return {
      type: data.type.charAt(0).toUpperCase() + data.type.slice(1).toLowerCase(),
      id: data.id,
      isExpired: data.isExpired,
      expiration: animalVetRecordExpirationConvertor.fromDb(data.expiration),
      documentFiles: (data.documentFiles || []).map((item) => documentFilesConvertor.fromDb(item)),
      status: vetRecordStatusConverter.fromDb(data.status),
    };
  },
};

export const applicationSADocPacketConvertor: TFetchConverter<TSADocPacketGetUI, TSADocPacketGet> = {
  fromDb: (data) => {
    return {
      id: data.id,
      saLetters: (data.saLetters || []).map((item) => documentFilesConvertor.fromDb(item)),
      status: docPacketStatusConverter.fromDb(data.status),
      trainedFor: data.trainedFor,
      trainerInfo: trainerInfoConvertor.fromDb(data.trainerInfo),
      startTime: data.trainingStartDate,
      endTime: data.trainingEndDate,
    };
  },
};

export const docsOverviewStatusConverter: TConvertor<EDocsOverviewStatusUI, EDocsOverviewStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EDocsOverviewStatusDB]: EDocsOverviewStatusUI } = {
      [EDocsOverviewStatusDB.HAS_UNVERIFIED]: EDocsOverviewStatusUI.HAS_UNVERIFIED,
      [EDocsOverviewStatusDB.HAS_VERIFIED]: EDocsOverviewStatusUI.HAS_VERIFIED,
      [EDocsOverviewStatusDB.MISSING]: EDocsOverviewStatusUI.MISSING,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EDocsOverviewStatusUI]: EDocsOverviewStatusDB } = {
      [EDocsOverviewStatusUI.HAS_UNVERIFIED]: EDocsOverviewStatusDB.HAS_UNVERIFIED,
      [EDocsOverviewStatusUI.HAS_VERIFIED]: EDocsOverviewStatusDB.HAS_VERIFIED,
      [EDocsOverviewStatusUI.MISSING]: EDocsOverviewStatusDB.MISSING,
    };

    return dataMapper[data];
  },
};

export const pictureOverviewStatusConverter: TConvertor<EPictureOverviewStatusUI, EPictureOverviewStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EPictureOverviewStatusDB]: EPictureOverviewStatusUI } = {
      [EPictureOverviewStatusDB.MISSING]: EPictureOverviewStatusUI.MISSING,
      [EPictureOverviewStatusDB.SPECIFIED]: EPictureOverviewStatusUI.SPECIFIED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EPictureOverviewStatusUI]: EPictureOverviewStatusDB } = {
      [EPictureOverviewStatusUI.MISSING]: EPictureOverviewStatusDB.MISSING,
      [EPictureOverviewStatusUI.SPECIFIED]: EPictureOverviewStatusDB.SPECIFIED,
    };

    return dataMapper[data];
  },
};

export const vetRecordOverviewConverter: TConvertor<EVetRecordOverviewStatusUI, EVetRecordOverviewStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EVetRecordOverviewStatusDB]: EVetRecordOverviewStatusUI } = {
      [EVetRecordOverviewStatusDB.HAS_UNVERIFIED]: EVetRecordOverviewStatusUI.HAS_UNVERIFIED,
      [EVetRecordOverviewStatusDB.HAS_VERIFIED]: EVetRecordOverviewStatusUI.HAS_VERIFIED,
      [EVetRecordOverviewStatusDB.MISSING]: EVetRecordOverviewStatusUI.MISSING,
      [EVetRecordOverviewStatusDB.NOT_REQUIRED]: EVetRecordOverviewStatusUI.NOT_REQUIRED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EVetRecordOverviewStatusUI]: EVetRecordOverviewStatusDB } = {
      [EVetRecordOverviewStatusUI.HAS_UNVERIFIED]: EVetRecordOverviewStatusDB.HAS_UNVERIFIED,
      [EVetRecordOverviewStatusUI.HAS_VERIFIED]: EVetRecordOverviewStatusDB.HAS_VERIFIED,
      [EVetRecordOverviewStatusUI.MISSING]: EVetRecordOverviewStatusDB.MISSING,
      [EVetRecordOverviewStatusUI.NOT_REQUIRED]: EVetRecordOverviewStatusDB.NOT_REQUIRED,
    };

    return dataMapper[data];
  },
};

export const dataOverviewConvertor: TFetchConverter<TDataOverviewUI, TDataOverviewDB> = {
  fromDb: (data) => {
    return {
      profilePicture: pictureOverviewStatusConverter.fromDb(data.profilePicture),
      vetRecord: vetRecordOverviewConverter.fromDb(data.vetRecord),
      ...(data.hpDocs && {
        hpDocs: docsOverviewStatusConverter.fromDb(data.hpDocs),
      }),
      ...(data.trainerDocs && {
        trainerDocs: docsOverviewStatusConverter.fromDb(data.trainerDocs),
      }),
    };
  },
};

export const getAnimalConverter: TFetchConverter<TAnimalDetailUi, TAnimalDetail> = {
  fromDb: (data) => {
    return {
      animal: animalBasicInfoConvertor.fromDb(data.animal),
      applications: (data.applications || []).map((item) => animalApplicationConvertor.fromDb(item)),
      dnaInfo: dnaInfoConvertor.fromDb(data.dnaInfo),
      hpDocPackets: (data.hpDocPackets || []).map((packet) => ({
        ...getHPDocPacketsConvertor.fromDb(packet),
      })),
      ownerInfo: animalOwnerConvertor.fromDb(data.ownerInfo),
      saDocuments: (data.saDocuments || []).map((item) => applicationSADocPacketConvertor.fromDb(item)),
      residencyStatus: animalResidencyStatusConverter.fromDb(data.residencyStatus),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
      isToPetConverted: data.isToPetConverted,
      vetRecords: (data.vetRecords || []).map((item) => animalVetRecordConvertor.fromDb(item)),
      dataOverview: dataOverviewConvertor.fromDb(data.dataOverview),
    };
  },
};

export const getAnimalStatisticsItemConverter: TFetchConverter<TAnimalStatisticUI, TAnimalStatisticDB> = {
  fromDb: (data) => {
    return {
      fromDate: formattedDate(data.fromDate),
      totalCount: data.totalCount,
    };
  },
};

export const getAnimalStatisticsConverter: TFetchConverter<TAnimalsStatisticsUI, TAnimalsStatisticsDB> = {
  fromDb: (data) => {
    return {
      convertedAnimals: data.convertedAnimals && getAnimalStatisticsItemConverter.fromDb(data.convertedAnimals),
      preventedAnimals: data.preventedAnimals && getAnimalStatisticsItemConverter.fromDb(data.preventedAnimals),
    };
  },
};

export const animalTerminateConverter: TCreateConverter<TAnimalTerminateUI, TAnimalTerminateDB> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      animalResidencyId: data.animalResidencyId,
      reason: data.reason,
    };
  },
};

export const applicantAnimalConverter: TFetchConverter<TApplicantAnimalUI, TApplicantAnimalDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      type: animalTypeConverter.fromDb(data.type),
      applicationStatus: applicationStatusConvertor.fromDb(data.applicationStatus),
      statusChangerRole: data.statusChangerRole ? statusChangerRoleConvertor.fromDb(data.statusChangerRole) : null,
      statusChangerName: data.statusChangerName,
      rejectReason: data.rejectReason,
      moveInDate: formattedDate(data.moveInDate),
      submittedDate: formattedDate(data.submittedDate),
      residentName: data.residentName,
      petName: data.petName,
      propertyName: data.propertyName,
      unitName: data.unitName,
      animalVerificationStatus: verificationStatusConverter.fromDb(data.animalVerificationStatus),
    };
  },
};

export const convertedAnimalConverter: TFetchConverter<TConvertedAnimalUI, TConvertedAnimalDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      name: data.name,
      profilePicture: data.profilePicture,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      birthday: data.birthday ? formattedDate(`${data.birthday}Z`, 'MM/DD/YYYY') : '',
      species: data.species ? selectOptionConverter.fromDb(data.species) : null,
      breed: data.breed ? selectOptionConverter.fromDb(data.breed) : null,
      weight: data.weight ? animalWeightConverter.fromDb(data.weight) : null,
      conversionDate: formattedDate(`${data.conversionDate}Z`, 'MM/DD/YYYY'),
      primaryColor: data.primaryColor ? predefinedOptionConverter.fromDb(data.primaryColor) : null,
      residence: data.residence ? animalResidenceConverter.fromDb(data.residence) : null,
      status: animalStatusConverter.fromDb(data.status),
      statusChangeInfo: data.statusChangeInfo ? statusChangeInfoConverter.fromDb(data.statusChangeInfo) : null,
      firstLetters: getFirstLetters(data.name),
    };
  },
};

export const preventedAnimalStatusConverter: TConvertor<EPreventedAnimalStatusUI, EPreventedAnimalStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EPreventedAnimalStatusDB]: EPreventedAnimalStatusUI } = {
      [EPreventedAnimalStatusDB.DENIED]: EPreventedAnimalStatusUI.DENIED,
      [EPreventedAnimalStatusDB.NONE]: EPreventedAnimalStatusUI.NONE,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EPreventedAnimalStatusUI]: EPreventedAnimalStatusDB } = {
      [EPreventedAnimalStatusUI.DENIED]: EPreventedAnimalStatusDB.DENIED,
      [EPreventedAnimalStatusUI.NONE]: EPreventedAnimalStatusDB.NONE,
    };

    return dataMapper[data];
  },
};

export const preventedAnimalConverter: TFetchConverter<TPreventedAnimalUI, TPreventedAnimalDB> = {
  fromDb: (data) => {
    return {
      animalId: data.animalId,
      animalBreed: data.animalBreed ? selectOptionConverter.fromDb(data.animalBreed) : null,
      animalCreationTime: formattedDate(data.animalCreationTime),
      animalName: data.animalName,
      animalPrimaryColor: data.animalPrimaryColor ? predefinedOptionConverter.fromDb(data.animalPrimaryColor) : null,
      animalProfilePicture: data.animalProfilePicture,
      animalSpecie: data.animalSpecie ? selectOptionConverter.fromDb(data.animalSpecie) : null,
      animalType: animalTypesConvertor.fromDb(data.animalType),
      animalVerificationStatus: verificationStatusConverter.fromDb(data.animalVerificationStatus),
      applicationRejectReason: data.applicationRejectReason,
      isAnimalToPetConverted: data.isAnimalToPetConverted,
      propertyName: data.propertyName,
      status: preventedAnimalStatusConverter.fromDb(data.status),
      tenantName: data.tenantName,
      unitNumber: data.unitNumber,
      firstLetters: getFirstLetters(data.animalName),
    };
  },
};
