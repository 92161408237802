import { TOptionTypeUi } from '@landlord-tech/ui-kit-opp';
import { docHtmlConvertor, getPolicyListConvertor } from 'antd/data/converters/policy.converters';
import { TPageableDataWithContentUi, TPageableDataUi } from 'antd/data/types/pagableData.type';
import { TDocHtmlUI, TPolicyGet } from 'antd/data/types/policy.types';
import { EPolicyUrls } from 'antd/data/urls/policy.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPolicyUrls.POLICY}`;

export const policyApi = authSplitApi('policy', []).injectEndpoints({
  endpoints: (build) => ({
    getPolicyOptions: build.query<TOptionTypeUi[], TPageableDataUi>({
      query(params) {
        return {
          url: `${process.env.REACT_APP_HOSTNAME}/api/v1/${EPolicyUrls.POLICY}`,
          method: 'GET',
          params: { ...params },
        };
      },
      transformResponse: (data: TPageableDataWithContentUi<TPolicyGet[]>) => {
        return data?.content?.length ? getPolicyListConvertor.fromDb(data.content) : [];
      },
    }),
    getDocHtml: build.query<TDocHtmlUI, { animalType: string; policyId: string }>({
      query({ animalType, policyId }) {
        return {
          url: `${baseUrl}/${EPolicyUrls.DOC}/${animalType}`,
          method: 'GET',
          responseHandler: 'text',
          params: { policyId },
        };
      },
      transformResponse: (data: string) => docHtmlConvertor.fromDb(data),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPolicyOptionsQuery, useGetDocHtmlQuery } = policyApi;
