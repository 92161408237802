import { updateBusinessNameConverter } from 'antd/data/converters/landlord.converter';
import { TUpdateBusinessNameUI } from 'antd/data/types/landlord.types';
import { EPropertyManagementUrl } from 'antd/data/urls/propertyManagement.url';
import { baseUrlsV2 } from 'api/services/endpointEnums';
import { authSplitApi } from '../helpers/slice.helpers';

const { PROPERTY_MANAGER } = baseUrlsV2;
export const propertyManagementApi = authSplitApi('propertyManagementApi', []).injectEndpoints({
  endpoints: (build) => ({
    updateBusinessName: build.mutation<void, TUpdateBusinessNameUI>({
      query(data) {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGEMENT}/${EPropertyManagementUrl.NAME}`,
          method: 'PATCH',
          body: updateBusinessNameConverter.toDb(data),
        };
      },
    }),
  }),
});

export const { useUpdateBusinessNameMutation } = propertyManagementApi;
