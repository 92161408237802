import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import {
  DashboardIcon,
  DocumentIcon,
  GraduationHatIcon,
  HomeIcon,
  NotebookIcon,
  PetPawIcon,
  SettingsSidebarIcon,
  SupportStaffIcon,
  ViolationsIcon,
} from 'components/BaseComponents/Icons';
import { allowedUserTypes } from 'routes/RoutesData';
import { useTabRelocation } from 'api/apiHooks/relocationHook';

const defaultTabs = [
  {
    label: 'Dashboard',
    route: 'dashboard',
    icon: <DashboardIcon />,
    dataCy: 'dashboard',
  },
  {
    label: 'Properties',
    route: 'properties',
    icon: <HomeIcon />,
    dataCy: 'properties',
  },
  {
    label: 'Residents',
    route: 'residents',
    icon: <SupervisorAccountIcon />,
  },
  {
    label: 'Team members',
    route: 'maintainers',
    icon: <SupportStaffIcon />,
    dataCy: 'managers',
    subRoutes: [
      { label: 'Pet mapping', route: 'maintainers', dataCy: 'maintainers' },
      { label: 'Property manager', route: 'property-managers', dataCy: 'propertyManagers' },
    ],
  },
  {
    label: 'Policies',
    route: 'policies',
    icon: <DocumentIcon />,
    dataCy: 'policies',
    subRoutes: [
      { label: 'Policy list', route: 'policies', dataCy: 'policies' },
      { label: 'Default state policies', route: 'default-policies', dataCy: 'default-policies' },
    ],
  },
  {
    label: 'Applications',
    route: 'applications',
    icon: <ViolationsIcon />,
  },
  {
    label: 'Animals',
    route: 'animals',
    icon: <ViolationsIcon />,
  },
];

export const useTabList = ({ impersonate, profile }) => {
  const { refetch: redirectTab } = useTabRelocation();
  const { landlord, admin, propertyManager } = allowedUserTypes;

  return {
    [landlord]: [
      ...defaultTabs,
      ...(profile.hasAccessToCourses
        ? [
            {
              label: 'Curriculum',
              route: 'curriculum',
              icon: <GraduationHatIcon color='#FCC425' />,
            },
          ]
        : []),
      {
        label: 'Complaints',
        route: 'complaints',
        icon: <ViolationsIcon />,
        dataCy: 'complaints',
      },
      {
        label: 'Settings',
        route: 'settings',
        icon: <SettingsSidebarIcon />,
        subRoutes: [
          { label: 'Payments', route: 'settings' },
          { label: 'Integrations', route: 'integration-settings' },
        ],
      },
      ...(impersonate
        ? []
        : [
            {
              label: 'Support',
              route: 'support',
              icon: <HelpIcon />,
              dataCy: 'support',
            },
          ]),
    ],
    [propertyManager]: [
      ...defaultTabs,
      ...(profile.hasAccessToCourses
        ? [
            {
              label: 'Curriculum',
              route: 'curriculum',
              icon: <GraduationHatIcon color='#FCC425' />,
            },
          ]
        : []),
      {
        label: 'Complaints',
        route: 'complaints',
        icon: <ViolationsIcon />,
        dataCy: 'complaints',
      },
      ...(impersonate
        ? []
        : [
            {
              label: 'Support',
              route: 'support',
              icon: <HelpIcon />,
              dataCy: 'support',
            },
          ]),
    ],
    [admin]: [
      {
        label: 'Animals',
        route: 'animals',
        icon: <PetPawIcon />,
        isButton: true,
        onClick: () => redirectTab('/animals'),
      },
      {
        label: 'Applications',
        route: 'applications',
        icon: <NotebookIcon />,
        isButton: true,
        onClick: () => redirectTab('/applications'),
      },
      {
        label: 'Tenants',
        route: 'tenants',
        icon: <SupervisorAccountIcon />,
        isButton: true,
        onClick: () => redirectTab('/tenants'),
      },
      {
        label: 'Landlords',
        route: 'landlords',
        icon: <BusinessIcon />,
      },
      {
        label: 'Curriculum',
        route: 'curriculum',
        icon: <GraduationHatIcon color='#FCC425' />,
      },
      {
        label: 'State policy',
        route: 'state-policy',
        icon: <DescriptionIcon />,
      },
      {
        label: 'Document upload',
        route: 'documents',
        icon: <ViolationsIcon />,
      },
      {
        label: 'Suggested texts',
        route: 'suggested-texts',
        icon: <FontDownloadIcon />,
      },
    ],
  };
};
