export enum ENotificationDB {
  VERIFIED_ANIMAL_PENDING_APPLICATIONS_REPORT = 'verifiedAnimalPendingApplicationsReport',
  RECENTLY_MOVED_ANIMALS = 'recentlyMovedOutAnimals',
  DNA_NOT_COLLECTED_ANIMALS = 'dnaNotCollectedAnimals',
  TENANT_RESIDENCY_APPLICATION_CREATED = 'tenantResidencyApplicationCreated',
  ANIMAL_APPLICATION_CREATED = 'animalApplicationCreated',
  ANIMAL_APPLICATION_APPROVED = 'animalApplicationApproved',
  ANIMAL_APPLICATION_REJECTED = 'animalApplicationRejected',
  ANIMAL_APPLICATION_MISSING_DATA_PROVIDED = 'animalApplicationMissingDataProvided',
  PENDING_ANIMAL_APPLICATION_WITH_MISSING_DATA = 'pendingAnimalApplicationsWithMissingData',
}

export enum ENotificationUI {
  VERIFIED_ANIMAL_PENDING_APPLICATIONS_REPORT = 'verifiedAnimalPendingApplicationsReport',
  RECENTLY_MOVED_ANIMALS = 'recentlyMovedOutAnimals',
  DNA_NOT_COLLECTED_ANIMALS = 'dnaNotCollectedAnimals',
  TENANT_RESIDENCY_APPLICATION_CREATED = 'tenantResidencyApplicationCreated',
  ANIMAL_APPLICATION_CREATED = 'animalApplicationCreated',
  ANIMAL_APPLICATION_APPROVED = 'animalApplicationApproved',
  ANIMAL_APPLICATION_REJECTED = 'animalApplicationRejected',
  ANIMAL_APPLICATION_MISSING_DATA_PROVIDED = 'animalApplicationMissingDataProvided',
  PENDING_ANIMAL_APPLICATION_WITH_MISSING_DATA = 'pendingAnimalApplicationsWithMissingData',
}
