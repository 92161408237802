import { TSADocPacketGet, TSADocPacketGetUI } from './animalTraining.types';
import { TDocPacketGet, TDocPacketGetUI } from './document.types';
import {
  EApplicationStatus,
  EApplicationStatusUi,
  EGender,
  EGenderUi,
  EReproductive,
  TOptionType,
  TOptionTypePredefinedDB,
  TOptionTypeUi,
  TPolicyUI,
  TPropertyUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TUnitUI,
} from './general.types';
import { EVerificationStatus, EVerificationStatusUi } from './generalTypes';
import {
  EAnimalResidencyStatusDB,
  EAnimalResidencyStatusUI,
  EResidencyStatusDB,
  EResidencyStatusUI,
  TCompanyDB,
  TCompanyUi,
} from './residence.types';
import { TAnimalVetRecord, TAnimalVetRecordUi } from './vetRecord.types';
import { EUserRolesDB, EUserRolesUI } from './user.types';

export enum EAnimalTypes {
  ESA = 'ESA',
  PET = 'PET',
  SA = 'SA',
}
export enum EAnimalTypesUI {
  ESA = 'ESA',
  PET = 'PET',
  SA = 'SA',
}
export type TPropertyDB = {
  name: string;
  propertyId: string;
};

export enum EAnimalStatusDB {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export enum EAnimalStatusUI {
  ACTIVE = 'Resident',
  TERMINATED = 'Former resident',
}

export enum EPreventedAnimalStatusDB {
  NONE = 'NONE',
  DENIED = 'DENIED',
}

export enum EPreventedAnimalStatusUI {
  NONE = 'None',
  DENIED = 'Not Approved',
}

export enum EAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
}

export enum EAuthorizationStatusUi {
  AUTHORIZED = 'Authorized',
}

export enum EResidenceStatusDB {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export enum EResidenceStatusUI {
  ACTIVE = 'Active',
  TERMINATED = 'Terminated',
}

export enum EPictureOverviewStatusUI {
  MISSING = 'MISSING',
  SPECIFIED = 'SPECIFIED',
}

export enum EPictureOverviewStatusDB {
  MISSING = 'MISSING',
  SPECIFIED = 'SPECIFIED',
}

export enum EVetRecordOverviewStatusUI {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum EVetRecordOverviewStatusDB {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum EDocsOverviewStatusUI {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
}

export enum EDocsOverviewStatusDB {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
}

export type TAnimalResidenceFilterDB = {
  propertyName: string;
  residenceIds: string[];
  residentName: string;
  uniNumber: string;
};

export type TAnimalResidenceFilterUI = {
  id: string;
};
export type TResidentProfileDB = {
  name: string;
  residentId: string;
};

export type TResidentProfileUI = {
  name: string;
  residentId: string;
};

export type TAnimalUnitDB = {
  number: string;
  property: TPropertyDB;
};

export type TAnimalUnitUI = {
  number: string;
  property: TPropertyDataUi;
};

export type TAnimalResidenceDB = {
  dnaEnabled: boolean | null;
  residenceId: string;
  residentProfile: TResidentProfileDB;
  status: EResidenceStatusDB | null;
  unit: TAnimalUnitDB;
};

export type TAnimalResidenceUI = {
  dnaEnabled: boolean | null;
  residenceId: string;
  residentProfile: TResidentProfileUI;
  status: EResidenceStatusUI | null;
  unit: TAnimalUnitUI;
};

export type TInfoDNA = {
  isDnaRequired: boolean;
  isDnaCollected: boolean;
  dnaNumber: string | null;
  dnaCompany: TCompanyDB | null;
};

export type TInfoDNAUi = {
  isDnaRequired: boolean;
  isDnaCollected: boolean;
  dnaNumber: string | null;
  dnaCompany: TCompanyUi | null;
};

export type TDnaInfoDB = {
  company: string | null;
  companyCustomName: string | null;
  dnaNumber: string | null;
  isCollected: false;
};

export type TDnaInfoUI = {
  company: string;
  companyCustomName: string;
  dnaNumber: string;
  isCollected: false;
};

export type TAnimalWeightDB = {
  unit: string;
  value: number;
};

export type TAnimalWeightUI = {
  unit: string;
  value: number;
};

export type TAnimalDB = {
  id: string;
  name: string;
  animalVerificationStatus: EVerificationStatus;
  birthday: string;
  breed: TOptionType | null;
  species: TOptionType | null;
  primaryColor: TOptionTypePredefinedDB | null;
  deleted: boolean;
  dnaInfo: TDnaInfoDB;
  propertyName: string;
  profilePicture: string;
  type: EAnimalTypes;
  unitNumber: string;
  status: EAnimalStatusDB;
  weight: TAnimalWeightDB | null;
  residence: TAnimalResidenceDB | null;
  moveOutDate: string | null;
  moveOutReason: string | null;
};

export type TAnimalUI = {
  id: string;
  name: string;
  animalVerificationStatus: EVerificationStatusUi;
  birthday: string;
  breed: TOptionTypeUi | null;
  species: TOptionTypeUi | null;
  primaryColor: TOptionTypeUi | null;
  deleted: boolean;
  dnaInfo: TDnaInfoUI | null;
  propertyName: string;
  profilePicture: string;
  type: EAnimalTypesUI;
  unitNumber: string;
  status: EAnimalStatusUI;
  weight: TAnimalWeightUI | null;
  residence: TAnimalResidenceUI | null;
  firstLetters: string;
  moveOutDate: string;
  moveOutReason: string;
};

export type TConvertedAnimalDB = {
  id: string;
  name: string;
  profilePicture: string;
  propertyName: string;
  unitNumber: string;
  birthday: string;
  breed: TOptionType | null;
  species: TOptionType | null;
  weight: TAnimalWeightDB | null;
  conversionDate: string;
  primaryColor: TOptionTypePredefinedDB | null;
  residence: TAnimalResidenceDB | null;
  status: EAnimalStatusDB;
  statusChangeInfo: TStatusChangeInfoDB | null;
};

export type TConvertedAnimalUI = {
  id: string;
  name: string;
  profilePicture: string;
  propertyName: string;
  unitNumber: string;
  birthday: string;
  breed: TOptionTypeUi | null;
  species: TOptionTypeUi | null;
  weight: TAnimalWeightUI | null;
  conversionDate: string;
  primaryColor: TOptionTypeUi | null;
  residence: TAnimalResidenceUI | null;
  status: EAnimalStatusUI;
  statusChangeInfo: TStatusChangeInfoUI | null;
  firstLetters: string;
};

export type TPreventedAnimalDB = {
  animalId: string;
  animalBreed: TOptionType | null;
  animalCreationTime: string;
  animalName: string;
  animalPrimaryColor: TOptionTypePredefinedDB | null;
  animalProfilePicture: string;
  animalSpecie: TOptionType | null;
  animalType: EAnimalTypes;
  animalVerificationStatus: EVerificationStatus;
  applicationRejectReason: string | null;
  isAnimalToPetConverted: string;
  propertyName: string;
  status: EPreventedAnimalStatusDB;
  tenantName: string;
  unitNumber: string;
};

export type TPreventedAnimalUI = {
  animalId: string;
  animalBreed: TOptionTypeUi | null;
  animalCreationTime: string;
  animalName: string;
  animalPrimaryColor: TOptionTypeUi | null;
  animalProfilePicture: string;
  animalSpecie: TOptionTypeUi | null;
  animalType: EAnimalTypesUI;
  animalVerificationStatus: EVerificationStatusUi;
  applicationRejectReason: string | null;
  isAnimalToPetConverted: string;
  propertyName: string;
  status: EPreventedAnimalStatusUI;
  tenantName: string;
  unitNumber: string;
  firstLetters: string;
};

export type TAnimalTerminateDB = {
  residenceId: string;
  animalResidencyId: string;
  reason: string;
};

export type TAnimalTerminateUI = {
  residenceId: string;
  animalResidencyId: string;
  reason: string;
};

export type TAnimalBasicInfo = {
  birthDate: string;
  breed: string;
  gender: EGender;
  id: string;
  isHouseTrained: boolean | null;
  isReproductive: boolean | null;
  name: string;
  photoGallery: string[];
  primaryColor: string | null;
  profilePicture: string | null;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  species: string | null;
  verificationStatus: EVerificationStatus;
  type: EAnimalTypes;
  weight: TAnimalWeightDB | null;
};

export type TAnimalBasicInfoUi = {
  birthDate: string | null;
  breed: string;
  gender: EGenderUi;
  id: string;
  isHouseTrained: boolean | null;
  reproduction: EReproductive | null;
  name: string;
  photoGallery: string[];
  primaryColor: string | null;
  profilePicture: string | null;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  species: string | null;
  verificationStatus: EVerificationStatusUi;
  type: EAnimalTypesUI;
  weight: TAnimalWeightUI | null;
};

export type TProperty = {
  propertyId: string;
  name: string;
};

export type TPropertyDataUi = {
  propertyId: string;
  name: string;
};

export type TUnit = {
  number: string;
  unitId: string;
  property: TProperty;
};

export type TUnitUi = {
  number: string;
  unitId: string;
  property: TPropertyDataUi;
};

export type TAnimalOwnerInfoUI = {
  id: string;
  email: string | null;
  moveInDate: string;
  leaseToDate: string | null;
  name: string;
  phoneNumber: string | null;
  policy: TPolicyUI;
  profilePicture: string | null;
  property: TPropertyUI;
  residencyStatus: EResidencyStatusUI;
  unit: TUnitUI;
};

export type TAnimalOwnerInfoDB = {
  id: string;
  email: string | null;
  moveInDate: string;
  leaseToDate: string | null;
  name: string;
  phoneNumber: string | null;
  policyId: string;
  policyName: string;
  policyState: string;
  profilePicture: string | null;
  propertyId: string;
  propertyName: string;
  residencyStatus: EResidencyStatusDB;
  unitId: string;
  unitName: string;
};

export type TDataOverviewUI = {
  profilePicture: EPictureOverviewStatusUI;
  vetRecord: EVetRecordOverviewStatusUI;
  hpDocs?: EDocsOverviewStatusUI;
  trainerDocs?: EDocsOverviewStatusUI;
};

export type TDataOverviewDB = {
  profilePicture: EPictureOverviewStatusDB;
  vetRecord: EVetRecordOverviewStatusDB;
  hpDocs?: EDocsOverviewStatusDB;
  trainerDocs?: EDocsOverviewStatusDB;
};

export type TAnimalApplication = {
  applicationStatus: EApplicationStatus;
  id: string;
  leaseStartDate: string;
  leaseToDate: string | null;
  policyId: string;
  propertyManagerName: string;
  propertyName: string;
  unitNumber: string;
};

export type TAnimalApplicationUi = {
  applicationStatus: EApplicationStatusUi;
  id: string;
  leaseStartDate: string;
  leaseToDate: string | null;
  policyId: string;
  propertyName: string;
  propertyManagerName: string;
  unitNumber: string;
};

export type TAnimalDetail = {
  animal: TAnimalBasicInfo;
  applications: TAnimalApplication[] | null;
  dnaInfo: TInfoDNA;
  ownerInfo: TAnimalOwnerInfoDB;
  vetRecords: TAnimalVetRecord[] | null;
  hpDocPackets: TDocPacketGet[] | null;
  residencyStatus: EAnimalResidencyStatusDB;
  residencyStatusChangeInfo: TStatusChangeInfoDB | null;
  saDocuments: TSADocPacketGet[] | null;
  dataOverview: TDataOverviewDB;
  isToPetConverted: boolean;
};

export type TAnimalDetailUi = {
  animal: TAnimalBasicInfoUi;
  applications: TAnimalApplicationUi[] | null;
  dnaInfo: TInfoDNAUi;
  ownerInfo: TAnimalOwnerInfoUI;
  vetRecords: TAnimalVetRecordUi[] | null;
  hpDocPackets: TDocPacketGetUI[] | null;
  residencyStatus: EAnimalResidencyStatusUI;
  residencyStatusChangeInfo: TStatusChangeInfoUI | null;
  saDocuments: TSADocPacketGetUI[] | null;
  dataOverview: TDataOverviewUI;
  isToPetConverted: boolean;
};

export type TAnimalStatisticUI = {
  fromDate: string;
  totalCount: number;
};

export type TAnimalStatisticDB = {
  fromDate: string;
  totalCount: number;
};

export type TAnimalsStatisticsUI = {
  convertedAnimals: TAnimalStatisticUI;
  preventedAnimals: TAnimalStatisticUI;
};

export type TAnimalsStatisticsDB = {
  convertedAnimals: TAnimalStatisticDB;
  preventedAnimals: TAnimalStatisticDB;
};

export enum TChangerStatusRolesDB {
  ADMIN = 'ADMIN',
  LANDLORD = 'LANDLORD',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}
export enum TChangerStatusRolesUI {
  ADMIN = 'admin',
  LANDLORD = 'landlord',
  PROPERTY_MANAGER = 'propertyManager',
}

export type TApplicantAnimalDB = {
  id: string;
  type: EAnimalTypes;
  applicationStatus: EApplicationStatus;
  statusChangerRole: TChangerStatusRolesDB | null;
  statusChangerName: string | null;
  rejectReason: string | null;
  moveInDate: string;
  submittedDate: string;
  residentName: string;
  petName: string;
  propertyName: string;
  unitName: string;
  animalVerificationStatus: EVerificationStatus;
};

export type TApplicantAnimalUI = {
  id: string;
  type: EAnimalTypesUI;
  applicationStatus: EApplicationStatusUi;
  statusChangerRole: TChangerStatusRolesUI | null;
  statusChangerName: string | null;
  rejectReason: string | null;
  moveInDate: string;
  submittedDate: string;
  residentName: string;
  petName: string;
  propertyName: string;
  unitName: string;
  animalVerificationStatus: EVerificationStatusUi;
};
