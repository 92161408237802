import {validationRules} from "antd/components/Form";
import {TResetPasswordFormData} from "./ResetPasswordForm.types";


export const resetPasswordFormData: TResetPasswordFormData = {
    code: {
        name: 'code',
        label: 'Confirmation code',
        rules: [validationRules.required()],
        placeholder: 'Confirmation code',
    },
    password: {
        name: 'password',
        label: 'New password (Min. of 6 characters)',
        rules: [
            ...validationRules.password,
            validationRules.required('password'),
            validationRules.withoutSpace('Password'),
        ],
        placeholder: 'Enter password',
    },
    passwordConfirm: {
        name: 'passwordConfirm',
        label: 'Repeat the password',
        rules: [
            ...validationRules.passwordConfirm,
            validationRules.required('confirm password'),
        ],
        placeholder: 'Repeat the password',
    },
}