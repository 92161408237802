export type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};

export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};

export enum EVerificationStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
}
export enum EVerificationStatusUi {
  NONE = 'Not verified',
  VERIFIED = 'Verified',
  PENDING = 'Pending',
}

export enum ETextMessage {
  DEFAULT_ERROR_MESSAGE = 'Something went wrong',
  DEFAULT_SUCCESS_MESSAGE = 'Successfully done',
  DEFAULT_WARNING_TITLE = 'Missing information',
  MISSING_DOCUMENTS = 'Missing documents',
  VACCINATION_NO_EXPIRE = 'Documentation is from a licensed veterinarian exempting my animal from a rabies vaccination due to the animals age/health condition.',
}
