import React, {FC, useEffect, useRef, useState} from 'react';
import {Col, Row} from 'antd/components/Grid';
import AuthFormsWrapper from "antd/componentsShared/AuthFormsWrapper";
import Alert from "antd/components/Alert";
import Form, {FormItem} from "antd/components/Form";
import {TextField} from "antd/components/TextField";
import useForm from "antd/hooks/useForm";
import {Link, useHistory} from "react-router-dom";
import {useSetNewPasswordMutation} from "antd/redux/apiSlices/auth.slice";
import get from "lodash/get";
import Button from "antd/components/Button";
import {Paragraph, Text} from "antd/components/Typography";
import {Colors} from "core/CssVariables";
import {RoutesNames} from 'routes/RoutesNames';
import {TSetPasswordFormUI} from 'antd/data/types/auth.types';
import {setPasswordFormData} from "./SetPasswordForm.data";
import {TSetPasswordFormProps} from "./SetPasswordForm.types";


const {password, passwordConfirm} = setPasswordFormData;

const SetPasswordForm: FC<TSetPasswordFormProps> = ({email, temporaryPassword}) => {
    const history = useHistory();
    const {form} = useForm<TSetPasswordFormUI>();
    const [error, setError] = useState<string>('');
    const [setNewPassword, {isLoading}] = useSetNewPasswordMutation();
    const alertRef = useRef<HTMLDivElement | null>(null);
    const onSubmit = async (values: TSetPasswordFormUI) => {
        try {
            await setNewPassword({email, password: values.password, temporaryPassword}).unwrap();
            setError('');
            history.push('/')
        } catch (err) {
            const message = get(err, ['data', 'message']) || 'Something went wrong!';
            setError(message);
        }
    }
    useEffect(() => {
        if (error) {
            alertRef.current?.scrollIntoView({block: 'end'})
        }
    }, [error])
    return (
        <Row justify='center'>
            <AuthFormsWrapper title='Create a new password'>
                <Form form={form} onFinish={onSubmit} requiredMark={false} scrollToFirstError>
                    <Row>
                        <Col span={24} ref={alertRef}>
                            <Alert type='error' message={error}/>
                        </Col>
                        <Col span={24}>
                            <FormItem name={password.name} rules={password.rules} label={password.label}>
                                <TextField placeholder={password.placeholder} type='password'/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={passwordConfirm.name} rules={passwordConfirm.rules}
                                      label={passwordConfirm.label}>
                                <TextField placeholder={passwordConfirm.placeholder} type='password'/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <Button htmlType='submit' loading={isLoading}>Save</Button>
                            <Paragraph className='mt-2' fontLevel={5}>
                                Or go back to{' '}
                                <Text fontLevel={5} color={Colors.DarkBlue} underline>
                                    <Link to={RoutesNames.SignIn}> Login page</Link>
                                </Text>
                            </Paragraph>
                        </Col>
                    </Row>
                </Form>
            </AuthFormsWrapper>
        </Row>
    );
};

export default SetPasswordForm;
