import { useCallback } from 'react';

export const useTabRelocation = (onSuccess = () => {}, onError = () => {}) => {
  const refetch = useCallback((to) => {
    const admin = JSON.parse(localStorage.getItem('user'));
    const newWindow = window.open(`${process.env.REACT_APP_ADMIN_HOSTNAME}/redirect`, '_blank');
    if (!newWindow) {
      onError();
      return;
    }
    setTimeout(() => {
      newWindow.postMessage(JSON.stringify({ ...admin, to }), `${process.env.REACT_APP_ADMIN_HOSTNAME}/redirect`);
      onSuccess();
    }, 1000);
  }, []);

  return { refetch };
};
