import {
  EMethodDB,
  TAnimalApplicantDetail,
  TAnimalApplicantDetailUi,
  TAnimalResidenciesDetailsDB,
  TAnimalResidenciesDetailsUI,
  TApplicationDetailDB,
  TApplicationDetailsDB,
  TApplicationDetailsUI,
  TApplicationDetailUI,
  TAppMethodDetailsDB,
  TAppMethodDetailsUI,
  TApproveApplicationDB,
  TApproveApplicationFormProps,
  TApproveApplicationFormPropsUI,
  TApproveApplicationUI,
  TTenantResidenciesDB,
  TTenantResidenciesDetailsDB,
  TTenantResidenciesDetailsUI,
  TVerificationMessageDB,
  TVerificationMessageUi,
} from '../types/application.types';
import { TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  animalBasicInfoConvertor,
  animalOwnerConvertor,
  animalVetRecordConvertor,
  applicationSADocPacketConvertor,
  dataOverviewConvertor,
} from './animals.converter';
import {
  applicationPolicyConvertor,
  applicationPropertyConvertor,
  applicationResidentConvertor,
  applicationUnitConvertor,
  methodsConvertor,
} from './applicationGeneral.converters';
import { getHPDocPacketsConvertor } from './docPacket.convertors';
import { statusChangeInfoConverter } from './general.converters';
import { animalResidencyStatusConverter } from './residence.converters';

export const applicationMethodDetailsConvertor: TFetchConverter<TAppMethodDetailsUI, TAppMethodDetailsDB> = {
  fromDb: (data) => ({
    leaseToDate: data.leaseToDate || null,
    moveInDate: data.moveInDate || null,
    property: applicationPropertyConvertor.fromDb({
      propertyId: data.propertyId,
      propertyName: data.propertyName,
    }),
    policy: applicationPolicyConvertor.fromDb({
      policyId: data.policyId,
      policyName: data.policyName,
    }),
    unit: applicationUnitConvertor.fromDb({
      unitId: data.unitId,
      unitNumber: data.unitNumber,
    }),
  }),
};

export const applicationAnimalResidenciesConverter: TFetchConverter<
  TAnimalResidenciesDetailsUI,
  TAnimalResidenciesDetailsDB
> = {
  fromDb: (data) => {
    return {
      id: data.id,
      animalName: data.animalName,
      animalProfilePicture: data.animalProfilePicture,
      animalType: data.animalType,
      animalWeight: data.animalWeight,
      animalSpecie: data.animalSpecie,
    };
  },
};

export const applicationResidenciesConverter: TFetchConverter<
  TTenantResidenciesDetailsUI,
  TTenantResidenciesDetailsDB
> = {
  fromDb: (data) => {
    return {
      id: data.id,
      unitNumber: data.unitNumber,
      propertyName: data.propertyName,
      animalResidencies: data.animalResidencies.map((item) => applicationAnimalResidenciesConverter.fromDb(item)),
    };
  },
};

export const applicationDetailConverter: TFetchConverter<TApplicationDetailUI, TApplicationDetailDB> = {
  fromDb: (data) => {
    const residencyData = data as TTenantResidenciesDB;
    const appMethodDetailData = data as TAppMethodDetailsDB;
    return {
      ...((residencyData.residencies || [])?.length && {
        residencies: residencyData.residencies.map((item) => applicationResidenciesConverter.fromDb(item)),
      }),
      ...(!(residencyData.residencies || [])?.length && applicationMethodDetailsConvertor.fromDb(appMethodDetailData)),
    };
  },
};

export const applicationDetailsConvertor: TFetchConverter<TApplicationDetailsUI, TApplicationDetailsDB> = {
  fromDb: (data) => ({
    resident: applicationResidentConvertor.fromDb({
      residentPhone: data.residentPhone,
      residentName: data.residentName,
      residentEmail: data.residentEmail,
      residentProfilePicture: data.residentProfilePicture,
    }),
    method: methodsConvertor.fromDb(data.method),
    details: data.method === EMethodDB.SIMPLE ? null : applicationDetailConverter.fromDb(data.details[data.method]),
  }),
};

export const getApplicantAnimalDetailsConverter: TFetchConverter<TAnimalApplicantDetailUi, TAnimalApplicantDetail> = {
  fromDb: (data) => {
    return {
      animal: animalBasicInfoConvertor.fromDb(data.animal),
      hpDocPackets: (data.hpDocPackets || []).map((packet) => ({
        ...getHPDocPacketsConvertor.fromDb(packet),
      })),
      ownerInfo: animalOwnerConvertor.fromDb(data.ownerInfo),
      saDocuments: (data.saDocuments || []).map((item) => applicationSADocPacketConvertor.fromDb(item)),
      residencyStatus: animalResidencyStatusConverter.fromDb(data.residencyStatus),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
      vetRecords: (data.vetRecords || []).map((item) => animalVetRecordConvertor.fromDb(item)),
      dataOverview: dataOverviewConvertor.fromDb(data.dataOverview),
    };
  },
};

export const residencyDetailConverter: TFetchConverter<TApproveApplicationFormProps, TApproveApplicationFormPropsUI> = {
  fromDb: (data) => ({
    propertyId: data.property.value,
    unitNumber: data.unit.label,
    moveInDate: data.moveInDate,
    leaseToDate: data.leaseToDate,
  }),
};

export const approveApplicationConverter: TCreateConverter<TApproveApplicationUI, TApproveApplicationDB> = {
  toDb: (data) => {
    return {
      applicationId: data.applicationId,
      ...(data.residenceId && { residenceId: data.residenceId }),
      ...(data.residencyDetails && { residencyDetails: residencyDetailConverter.fromDb(data.residencyDetails) }),
    };
  },
};

export const applicationVerificationMessageConvertor: TFetchConverter<TVerificationMessageUi, TVerificationMessageDB> =
  {
    fromDb: (data) => ({
      message: data.result.length ? data.result[0].reason : undefined,
    }),
  };
