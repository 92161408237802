import {
  applicationDetailsConvertor,
  applicationVerificationMessageConvertor,
  approveApplicationConverter,
  getApplicantAnimalDetailsConverter,
} from 'antd/data/converters/application.converters';
import {
  TAnimalApplicantDetail,
  TAnimalApplicantDetailUi,
  TApplicationDetailsDB,
  TApplicationDetailsUI,
  TApproveApplicationDB,
  TApproveApplicationUI,
  TVerificationMessageDB,
  TVerificationMessageUi,
} from 'antd/data/types/application.types';
import { EApplicationUrls } from 'antd/data/urls/application.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EApplicationUrls.APPLICATION}`;

export const applicationApi = authSplitApi('applicationApi', [
  'applicationDetails',
  'applicationVerification',
  'applicantAnimal',
  'applications',
]).injectEndpoints({
  endpoints: (build) => ({
    approveApplication: build.mutation<TApproveApplicationDB, TApproveApplicationUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.APPROVE}`,
          method: 'PATCH',
          body: approveApplicationConverter.toDb(body),
        };
      },
    }),
    dontApproveAnimalApplicant: build.mutation<void, { applicationId: string; rejectReason: string }>({
      query({ applicationId, rejectReason }) {
        return {
          url: `${baseUrl}/${EApplicationUrls.REJECT}`,
          method: 'PATCH',
          body: { applicationId, rejectReason },
        };
      },
      invalidatesTags: ['applicantAnimal'],
    }),
    getApplicationDetails: build.query<TApplicationDetailsUI, { applicationId: string }>({
      query({ applicationId }) {
        return {
          url: `${baseUrl}/${EApplicationUrls.APPROVE}/${EApplicationUrls.DETAILS}`,
          method: 'GET',
          params: { applicationId },
        };
      },
      providesTags: ['applicationDetails'],
      transformResponse: (data: TApplicationDetailsDB) => applicationDetailsConvertor.fromDb(data),
    }),
    getApplicantAnimal: build.query<TAnimalApplicantDetailUi, string>({
      query(id) {
        return {
          url: `${baseUrl}/${EApplicationUrls.ANIMALS}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['applicantAnimal'],
      transformResponse: (data: TAnimalApplicantDetail) => getApplicantAnimalDetailsConverter.fromDb(data),
    }),
    getApplicationVerification: build.query<TVerificationMessageUi, { applicationId: string; propertyId: string }>({
      query(params) {
        return {
          url: `${baseUrl}/${EApplicationUrls.APPROVE}/${EApplicationUrls.VERIFY}`,
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: ['applicationVerification'],
      transformResponse: (data: TVerificationMessageDB) => applicationVerificationMessageConvertor.fromDb(data),
    }),
  }),
  overrideExisting: false,
});

export const {
  useApproveApplicationMutation,
  useGetApplicantAnimalQuery,
  useDontApproveAnimalApplicantMutation,
  useLazyGetApplicationDetailsQuery,
  useGetApplicationVerificationQuery,
} = applicationApi;
