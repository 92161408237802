import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'antd/components/Grid';
import AuthFormsWrapper from "antd/componentsShared/AuthFormsWrapper";
import Alert from "antd/components/Alert";
import Form, {FormItem} from "antd/components/Form";
import {TextField} from "antd/components/TextField";
import useForm from "antd/hooks/useForm";
import {EResetPasswordCasesUI, TResetPasswordCasesUI} from "antd/data/types/auth.types";
import {useResetPasswordCasesMutation,} from "antd/redux/apiSlices/auth.slice";
import get from "lodash/get";
import Button from "antd/components/Button";
import {Paragraph, Text, Title} from "antd/components/Typography";
import {Colors} from "core/CssVariables";
import {RoutesNames} from "routes/RoutesNames";
import {Link} from "react-router-dom";
import {resetPasswordEmailForm} from "./ResetPassword.data";
import Confirmation from "../Confirmation";
import ResetPasswordForm from '../ResetPasswordForm';


const {email} = resetPasswordEmailForm;

const ResetPassword = () => {
    const {form} = useForm<TResetPasswordCasesUI>();
    const [error, setError] = useState<string>('');
    const [resetPassword, {isLoading, data}] = useResetPasswordCasesMutation()
    const alertRef = useRef<HTMLDivElement | null>(null);
    const onSubmit = async (values: TResetPasswordCasesUI) => {
        try {
            await resetPassword(values).unwrap();
            setError('');

        } catch (err) {
            const message = get(err, ['data', 'message']) || 'Something went wrong!';
            setError(message);
        }
    }
    useEffect(() => {
        if (error) {
            alertRef.current?.scrollIntoView({block: 'end'})
        }
    }, [error])
    const getCorrectForm = (data: EResetPasswordCasesUI) => {
        switch (data) {
            case EResetPasswordCasesUI.RESENT_PASSWORD:
                return <ResetPasswordForm email={form.getFieldValue('email')}/>;
            case EResetPasswordCasesUI.RESEND_INVITATION:
                return <Confirmation email={form.getFieldValue('email')}/>;
            case EResetPasswordCasesUI.RESENT_CODE:
                return <Confirmation email={form.getFieldValue('email')}/>;
            default:
                return null;
        }
    }
    return (
        data ? getCorrectForm(data) :
            <Row justify='center'>
                <AuthFormsWrapper title='Enter your username.'>
                    <Title fontLevel={5} className='mb-6'>Enter your credentials to receive a reset link.</Title>
                    <Form form={form} onFinish={onSubmit} requiredMark={false} scrollToFirstError>
                        <Row>
                            <Col span={24} ref={alertRef}>
                                <Alert type='error' message={error}/>
                            </Col>
                            <Col span={24}>
                                <FormItem name={email.name} rules={email.rules} label={email.label}>
                                    <TextField placeholder={email.placeholder}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <Button htmlType='submit' loading={isLoading}>Submit</Button>
                                <Paragraph className='mt-2' fontLevel={5}>
                                    Go back to{' '}
                                    <Text fontLevel={5} color={Colors.DarkBlue} underline>
                                        <Link to={RoutesNames.SignIn}> Login page </Link>
                                    </Text>
                                </Paragraph>
                            </Col>
                        </Row>
                    </Form>
                </AuthFormsWrapper>
            </Row>
    );
};

export default ResetPassword
