import { Moment } from 'moment';
import {
  TMultiOptionTypeMaterialUI,
  TOptionTypeUi,
  TPolicyUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
} from './general.types';
import { EAnimalTypes, EAnimalTypesUI, TAnimalWeightDB } from './animals.types';
import { EVerificationStatus, EVerificationStatusUi } from './generalTypes';
import { TPropertyUI, TUnitUI } from './property.types';

export type TResidenceUnitDataDB = {
  id: string;
  number: string;
};

export type TResidencePropertyDataDb = {
  name: string;
  propertyId: string;
};

export type TResidenceUnitDataUI = {
  id: string;
  number: string;
};

export type TResidencePropertyDataUI = {
  id: string;
  name: string;
};

export type TResidenceDB = {
  residenceId: string;
  residentName: string;
  unit: TResidenceUnitDataDB;
  property: TResidencePropertyDataDb;
  status: EResidenceStatusDB;
};

export type TResidenceUI = {
  residenceId: string;
  residentName: string;
  unit: TResidenceUnitDataUI;
  property: TResidencePropertyDataUI;
  status: EResidenceStatusUi;
};

export type TResidenceFilterDB = {
  propertyName: string;
  residentName: string;
  unitNumber: string;
  residenceIds: string[];
};

export type TResidenceMoveInfoUI = {
  isPolicyChanging: boolean;
};

export type TResidenceMoveInfo = {
  isPolicyChanging: boolean;
};

export type TResidentMoveFormDataProps = {
  property: TOptionTypeUi;
  unit: TOptionTypeUi;
};

export type TResidentMoveUi = TResidentMoveFormDataProps & {
  residenceId: string;
};

export type TResidentMove = {
  residenceId: string;
  propertyId: string;
  unitNumber: string;
};

export type TResidenceFilterUI = {
  residenceProfile: string;
  residenceIds: string[];
};

export enum EResidenceStatusDB {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}
export enum EResidenceStatusUi {
  ACTIVE = 'Active',
  TERMINATED = 'Terminated',
}

export type TResidentSearchUI = TMultiOptionTypeMaterialUI;
export type TResidentSearchDB = { residentId: string; name: string };

export enum EPooCompaniesUi {
  POO_PRINTS = 'Poo Prints',
  MR_DOG_POOP = 'Mr. Dog Poop',
  OTHER = 'other',
}

export enum EPooCompaniesDB {
  POO_PRINTS = 'POO_PRINTS',
  MR_DOG_POOP = 'MR_DOG_POOP',
  OTHER = 'OTHER',
}

export type TCompanyDB = {
  companyOption: EPooCompaniesDB | string | null;
  companyCustomName: string | null;
};

export type TCompanyUi = {
  label: EPooCompaniesUi | string | null;
  value: EPooCompaniesDB | string | null;
};

export type TCollectDNAUi = {
  residenceId: string;
  animalResidencyId: string;
  company?: TCompanyUi;
  dnaNumber?: string;
};

export type TCollectDNA = {
  residenceId: string;
  animalResidencyId: string;
  company?: TCompanyDB;
  dnaNumber?: string;
};

export type TUnCollectDNAUi = {
  residenceId: string;
  animalResidencyId: string;
};

export type TUnCollectDNA = {
  residenceId: string;
  animalResidencyId: string;
};

export enum EAnimalResidencyStatusUI {
  RESIDENT = 'Resident',
  APPLICANT = 'Applicant',
  FORMER_RESIDENT = 'Former resident',
  DENIED = 'Not Approved',
  ARCHIVED = 'Archived',
  APPROVED = 'Approved',
}

export enum EAnimalResidencyStatusDB {
  RESIDENT = 'RESIDENT',
  APPLICANT = 'APPLICANT',
  FORMER_RESIDENT = 'FORMER_RESIDENT',
  DENIED = 'DENIED',
  ARCHIVED = 'ARCHIVED',
  APPROVED = 'APPROVED',
}

export enum EResidencyStatusUI {
  RESIDENT = 'Resident',
  APPLICANT = 'Applicant',
  FORMER_RESIDENT = 'Former resident',
  DENIED = 'Not Approved',
}

export enum EResidencyStatusDB {
  RESIDENT = 'RESIDENT',
  APPLICANT = 'APPLICANT',
  FORMER_RESIDENT = 'FORMER_RESIDENT',
  DENIED = 'DENIED',
}
export enum EResidentHasAnimalDB {
  HAS_NOT_ANIMAL = 'HAS_NOT_ANIMAL',
  HAS_ANIMAL = 'HAS_ANIMAL',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum EResidentHasAnimalUI {
  HAS_NOT_ANIMAL = 'HAS_NOT_ANIMAL',
  HAS_ANIMAL = 'HAS_ANIMAL',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export type TResidentProfileUI = {
  name: string;
  profilePicture: string;
  email: string;
  phoneNumber: string;
};

export type TResidentProfileDB = {
  name: string;
  profilePicture: string | null;
  email: string | null;
  phoneNumber: string | null;
};

export type TResidentResidencyDB = {
  residenceId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string;
  leaseToDate: string;
};

export type TApplicantResidencyDB = {
  residencyApplicationId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string | null;
  leaseToDate: string | null;
};
export type TResidentResidencyUI = {
  id: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string | null;
  leaseToDate: string | null;
};

export type TResidentAnimalDB = {
  type: EAnimalTypes;
  name: string;
  profilePicture: string | null;
  species: string | null;
  breed: string | null;
  weight: TAnimalWeightDB | null;
  verificationStatus: EVerificationStatus;
  residencyStatus: EAnimalResidencyStatusDB;
  id: string;
};

export type TResidentAnimalUI = {
  type: EAnimalTypesUI;
  name: string;
  profilePicture: string;
  species: string;
  breed: string;
  weight: string;
  verificationStatus: EVerificationStatusUi;
  residencyStatus: EAnimalResidencyStatusUI;
  id: string;
};

export type TResidentDetailsDB = {
  residencyStatus: EResidencyStatusDB;
  residentId: string;
  residenceId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string;
  leaseToDate: string;
  name: string;
  profilePicture: string | null;
  email: string | null;
  phoneNumber: string | null;
  policyId: string;
  policyName: string;
  policyState: string;
  petStatus: EResidentHasAnimalDB;
  roommatePetStatus: EResidentHasAnimalDB;
  animals: TResidentAnimalDB[];
  residencyStatusChangeInfo: TStatusChangeInfoDB | null;
};

export type TResidentDetailsUI = {
  residencyStatus: EResidencyStatusUI;
  residentId: string;
  residentProfile: TResidentProfileUI;
  residentResidency: TResidentResidencyUI;
  policy: TPolicyUI;
  petStatus: EResidentHasAnimalUI;
  roommatePetStatus: EResidentHasAnimalUI;
  animals: TResidentAnimalUI[];
  residencyStatusChangeInfo: TStatusChangeInfoUI | null;
};

export type TApplicantDetailsDB = {
  residencyStatus: EResidencyStatusDB;
  residentId: string;
  residencyApplicationId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string;
  leaseToDate: string;
  name: string;
  profilePicture: string | null;
  email: string | null;
  phoneNumber: string | null;
  policyId: string;
  policyName: string;
  policyState: string;
  petStatus: EResidentHasAnimalDB;
  roommatePetStatus: EResidentHasAnimalDB;
  animals: TResidentAnimalDB[];
  residencyStatusChangeInfo: TStatusChangeInfoDB | null;
};

export type TApplicantDetailsUI = {
  residencyStatus: EResidencyStatusUI;
  residentId: string;
  residentProfile: TResidentProfileUI;
  residentResidency: TResidentResidencyUI;
  policy: TPolicyUI;
  petStatus: EResidentHasAnimalUI;
  roommatePetStatus: EResidentHasAnimalUI;
  animals: TResidentAnimalUI[];
  residencyStatusChangeInfo: TStatusChangeInfoUI | null;
};

export type TResidencyApplicationApplyDB = {
  residencyApplicationId: string;
  propertyId: string;
  unitName: string;
  moveInDate: string;
  leaseToDate: string;
};

export type TResidencyApplicationApplyUI = {
  id: string;
  property: { value: TPropertyUI | string; label: string };
  unit: { value: TUnitUI | string; label: string };
  endTime: Moment;
  startTime: Moment;
};

export type TResidencyApplicationRejectDB = {
  residencyApplicationId: string;
  reason: string;
};

export type TResidencyApplicationRejectUI = {
  id: string;
  reason: string;
};
