import { TExpirationDateType, TFileDataType, TFileDB, TFileUI } from './general.types';

export enum EVetRecordStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}

export enum EVetRecordStatusUi {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}

export type TAnimalVetRecord = {
  documentFiles: TFileDataType[];
  expiration: TExpirationDateType;
  id: string;
  isExpired: boolean;
  type: string;
  status: EVetRecordStatus;
};

export type TAnimalVetRecordUi = {
  documentFiles: TFileDataType[];
  expiration: TExpirationDateType;
  id: string;
  isExpired: boolean;
  type: string;
  status: EVetRecordStatusUi;
};
