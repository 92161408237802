import { TAmplifyError } from 'antd/data/types/amplify.types';
import { EUserRolesUI, TGetUserDataUI, TUserPropertyManagerUI } from 'antd/data/types/user.types';
import {
  getAdminUserService,
  getPropertyManagerUserService,
  getUserTypeDataService,
  impersonateUser,
} from 'antd/redux/apiServices/user.services';
import { EAdminUrl } from 'antd/data/urls/admin.url';
import { EUserUrls } from 'antd/data/urls/user.url';
import { TSignInAmplifyUI } from 'antd/data/types/auth.types';
import { authSplitApi } from '../helpers/slice.helpers';
import { clearAllCachedData } from '../store';

export const userApi = authSplitApi('user', ['userType', 'users']).injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<TGetUserDataUI | null, TSignInAmplifyUI>({
      // @ts-ignore
      async queryFn(arg, { dispatch }, options, baseQuery) {
        try {
          const userType = await getUserTypeDataService(baseQuery);
          if (userType?.type === EUserRolesUI.ADMIN) {
            const adminUser = await getAdminUserService(baseQuery);
            if (adminUser?.impersonateOn) {
              const propertyManagerUser = await getPropertyManagerUserService(baseQuery);
              const usersData = {
                [EUserRolesUI.ADMIN]: adminUser,
                ...(propertyManagerUser?.type && {
                  [propertyManagerUser.type]: propertyManagerUser,
                }),
              };
              return { data: { users: usersData, userType: propertyManagerUser?.type, impersonateOn: true } };
            }
            return { data: { userType: userType.type, users: { [EUserRolesUI.ADMIN]: adminUser } } };
          }
          if (userType?.type === EUserRolesUI.LANDLORD || userType?.type === EUserRolesUI.PROPERTY_MANAGER) {
            const propertyManagerUser = await getPropertyManagerUserService(baseQuery);
            return {
              data: propertyManagerUser?.type
                ? {
                    userType: propertyManagerUser?.type,
                    users: { [propertyManagerUser.type]: propertyManagerUser },
                  }
                : null,
            };
          }
          return { data: null };
        } catch (e) {
          const error = e as TAmplifyError;
          return { error };
        }
      },
      providesTags: ['users'],
    }),
    impersonateUser: build.mutation<TUserPropertyManagerUI, null>({
      // @ts-ignore
      async queryFn(arg, { dispatch }, options, baseQuery) {
        try {
          clearAllCachedData();
          await impersonateUser(baseQuery, arg);
          const data = await getPropertyManagerUserService(baseQuery);
          return { data };
        } catch (e) {
          const error = e as TAmplifyError;
          return { error };
        }
      },
    }),
    deleteImpersonation: build.mutation<void, void>({
      query() {
        return {
          url: `${EAdminUrl.ADMIN}/${EUserUrls.PROFILE}/${EUserUrls.IMPERSONATION}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const { useGetUsersQuery, useImpersonateUserMutation, useDeleteImpersonationMutation } = userApi;
