import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, setupListeners } from '@reduxjs/toolkit/dist/query';
import { complaintsApi } from './apiSlices/complaints.slice';
import { noticeApi } from './apiSlices/notices.slice';
import { integrationApi } from './apiSlices/integration.slice';
import { applicationApi } from './apiSlices/application.slice';
import { policyApi } from './apiSlices/policy.slice';
import appSlice from './slice/app.slice';
import { storageApi } from './apiSlices/storage.slice';
import { residenceApi } from './apiSlices/residence.slice';
import { propertyApi } from './apiSlices/property.slice';
import { profileApi } from './apiSlices/profile.slice';
import { maintainerApi } from './apiSlices/maintainer.slice';
import { animalApi } from './apiSlices/animals.slice';
import { generalApi } from './apiSlices/generalApis.slice';
import { leasePacketApi } from './apiSlices/leasePacket.slice';
import { authApi } from './apiSlices/auth.slice';
import { userApi } from './apiSlices/user.slice';
import usersSlice from './slice/users.slice';
import { propertyManagementApi } from './apiSlices/propertyManagement.slice';
import { visitationApi } from './apiSlices/visitation.slice';

export const rootReducer = combineReducers({
  appSlice: appSlice.reducer,
  usersSlice: usersSlice.reducer,
  [complaintsApi.reducerPath]: complaintsApi.reducer,
  [noticeApi.reducerPath]: noticeApi.reducer,
  [integrationApi.reducerPath]: integrationApi.reducer,
  [propertyManagementApi.reducerPath]: propertyManagementApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [storageApi.reducerPath]: storageApi.reducer,
  [residenceApi.reducerPath]: residenceApi.reducer,
  [propertyApi.reducerPath]: propertyApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [maintainerApi.reducerPath]: maintainerApi.reducer,
  [animalApi.reducerPath]: animalApi.reducer,
  [generalApi.reducerPath]: generalApi.reducer,
  [leasePacketApi.reducerPath]: leasePacketApi.reducer,
  [visitationApi.reducerPath]: visitationApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.error('We got a rejected action!', action);
  }
  return next(action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      complaintsApi.middleware,
      noticeApi.middleware,
      integrationApi.middleware,
      propertyManagementApi.middleware,
      applicationApi.middleware,
      policyApi.middleware,
      storageApi.middleware,
      residenceApi.middleware,
      propertyApi.middleware,
      profileApi.middleware,
      maintainerApi.middleware,
      animalApi.middleware,
      generalApi.middleware,
      leasePacketApi.middleware,
      visitationApi.middleware,
      authApi.middleware,
      userApi.middleware,
      rtkQueryErrorLogger
    ),
});

export const clearAllCachedData = () => {
  store.dispatch(complaintsApi.util.resetApiState());
  store.dispatch(noticeApi.util.resetApiState());
  store.dispatch(residenceApi.util.resetApiState());
  store.dispatch(integrationApi.util.resetApiState());
  store.dispatch(propertyManagementApi.util.resetApiState());
  store.dispatch(applicationApi.util.resetApiState());
  store.dispatch(policyApi.util.resetApiState());
  store.dispatch(storageApi.util.resetApiState());
  store.dispatch(propertyApi.util.resetApiState());
  store.dispatch(profileApi.util.resetApiState());
  store.dispatch(maintainerApi.util.resetApiState());
  store.dispatch(animalApi.util.resetApiState());
  store.dispatch(generalApi.util.resetApiState());
  store.dispatch(leasePacketApi.util.resetApiState());
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(visitationApi.util.resetApiState());
};

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
setupListeners(store.dispatch);

export type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
  | {
      error: E;
      data?: undefined;
      meta?: M;
    }
  | {
      error?: undefined;
      data: T;
      meta?: M;
    };

export type BaseQueryApi = {
  signal: AbortSignal;
  dispatch: ThunkDispatch<any, any, any>;
  getState: () => unknown;
};
export type MaybePromise<T> = T | PromiseLike<T>;

export type BaseQueryFn<
  Args = any,
  Result = unknown,
  Error = unknown,
  DefinitionExtraOptions = {
    /* do nothing */
  },
  Meta = {
    /* do nothing */
  }
> = (
  args: Args,
  api: BaseQueryApi,
  extraOptions: DefinitionExtraOptions
) => MaybePromise<QueryReturnValue<Result, Error, Meta>>;

export type BaseQuery = (
  arg: string | FetchArgs
) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>;
