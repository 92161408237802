import {
  EResetPasswordCasesDB,
  EResetPasswordCasesUI,
  TConfirmSignUpDB,
  TConfirmSignUpUI,
  TResetPasswordCasesDB,
  TResetPasswordCasesUI,
  TResetPasswordFormDB,
  TResetPasswordFormUI,
  TSetPasswordFormDB,
  TSetPasswordFormUI,
  TSignInAmplifyDB,
  TSignInAmplifyUI,
  TSignupDataDB,
  TSignupDataUI,
} from 'antd/data/types/auth.types';
import { TAuthConverter, TCreateConverter } from '../types/converter.types';

export const signupDataConverter: TCreateConverter<TSignupDataUI, TSignupDataDB> = {
  toDb: ({ businessName, fullName, password, email, phone }) => {
    return {
      businessName,
      name: fullName,
      password,
      email,
      phone: phone ? `+${phone}` : undefined,
    };
  },
};

export const signInAmplifyConverter: TCreateConverter<TSignInAmplifyUI, TSignInAmplifyDB> = {
  toDb: ({ email, password }) => {
    return { username: email, password };
  },
};

export const resetPasswordCasesConverter: TAuthConverter<
  TResetPasswordCasesUI,
  TResetPasswordCasesDB,
  EResetPasswordCasesUI,
  EResetPasswordCasesDB
> = {
  toDb: ({ email }) => {
    return {
      username: email,
    };
  },
  fromDb: (data) => {
    const dataMapper: { [key in EResetPasswordCasesDB]: EResetPasswordCasesUI } = {
      [EResetPasswordCasesDB.RESEND_INVITATION]: EResetPasswordCasesUI.RESEND_INVITATION,
      [EResetPasswordCasesDB.RESENT_CODE]: EResetPasswordCasesUI.RESENT_CODE,
      [EResetPasswordCasesDB.RESENT_PASSWORD]: EResetPasswordCasesUI.RESENT_PASSWORD,
    };
    return dataMapper[data];
  },
};

export const resetPasswordFormConverter: TCreateConverter<TResetPasswordFormUI, TResetPasswordFormDB> = {
  toDb: ({ email, password, code }) => {
    return { username: email, password, code: code.trim() };
  },
};

export const confirmSIgnUpConverter: TCreateConverter<TConfirmSignUpUI, TConfirmSignUpDB> = {
  toDb: ({ email, code }) => {
    return { username: email, code: code.trim() };
  },
};

export const setNewPasswordConverter: TCreateConverter<TSetPasswordFormUI, TSetPasswordFormDB> = {
  toDb: ({ password, email, temporaryPassword }) => {
    return { temporaryPassword, username: email, newPassword: password };
  },
};
