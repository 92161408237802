export type TOptionTypeUi = {
  id?: string;
  __isNew__?: boolean;
  value: string | any;
  label: string;
};

export type TOptionType = {
  id?: string;
  value?: string;
};

export enum EGenderUi {
  FEMALE = 'Female',
  MALE = 'Male',
}

export enum EGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum EReproductive {
  SPAYED = 'Spayed',
  NEUTERED = 'Neutered',
}

export enum EFileTypeUI {
  VIDEO = 'video',
  PHOTO = 'image',
  OTHER = 'other',
}

export enum EFileTypeDB {
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  OTHER = 'other',
}

export enum EApplicationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum EApplicationStatusUi {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Not Approved',
  ARCHIVED = 'Archived',
}

export type TFileUI = {
  url: string;
  name: string;
  type: EFileTypeUI;
};

export type TFileDB = {
  url: string;
  name: string;
  type?: EFileTypeDB;
};

export enum EValueEditingStatesUi {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
}

export type TExpirationDateType = {
  expirable: boolean;
  expired: boolean;
  date: string;
};

export type TDNARecordType = {
  company?: string;
  companyCustomName?: string;
  isEnabled: false;
};

export type TURLTypes = {
  docType: string;
  preSignedUrl: string;
};

export type TFileDataType = {
  createdBy: string;
  createdDate: string;
  documentFileId: string;
  name: string;
  url: string;
};

export type TOptionTypeDB = {
  id: string;
  name: string;
};

export type TMultiOptionTypeMaterialUI = {
  value: string;
  name: string;
};

export type TOptionTypePredefinedDB = {
  predefined: boolean;
  predefinedId: string;
  value: string;
};

export type TOptionWithOtherUI = {
  title: string;
  keyWord: string;
};

export type TStatusChangeInfoUI = {
  by?: string;
  date?: string;
  reason?: string;
};

export type TStatusChangeInfoDB = {
  by?: string;
  date?: string;
  reason?: string;
};

export enum EPetQuantityFilterUI {
  ZERO = '0',
  MORE_THAN_ZERO = 'More than 0',
}

export enum EPetQuantityFilterDB {
  ZERO = 'ZERO',
  MORE_THAN_ZERO = 'MORE_THAN_ZERO',
}

export type TPolicyDB = {
  policyId: string;
  policyName: string;
  policyState: string;
};

export type TPolicyUI = {
  policyId: string;
  policyName: string;
  policyState: string;
};

export type TUnitDB = {
  unitId: string;
  unitName: string;
};

export type TUnitUI = {
  unitId: string;
  unitName: string;
};

export type TPropertyDB = {
  propertyId: string;
  propertyName: string;
};

export type TPropertyUI = {
  propertyId: string;
  propertyName: string;
};
