import React from 'react';
import get from 'lodash/get';
import axios from './axios';
import { bugsnagClient } from './bugsnag';

class ErrorBoundary extends React.Component {
  componentDidMount() {
    this.requestInterceptor = axios.interceptors.request.use((req) => {
      return req;
    });

    this.responseInterceptor = axios.interceptors.response.use(
      (res) => res,
      (error) => {
        const errorMetadata = error?.response?.config
          ? {
              url: error.response.config.url,
              method: error.response.config.method,
              params: error.response.config.params,
              message: get(error, 'response.data.message'),
              data: error.response.config.data,
              statusCode: get(error, 'response.status', '404'),
            }
          : {};

        const { id, username, fullName } = this.props.profile || {};
        if (bugsnagClient) {
          bugsnagClient.setUser(id, username, fullName);
          bugsnagClient.addMetadata('metadata', errorMetadata);
          bugsnagClient.notify(error);
        }

        throw error;
      }
    );
  }

  componentDidCatch(error) {
    if (error?.name?.includes('ChunkLoadError')) {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    axios.interceptors.request.eject(this.requestInterceptor);
    axios.interceptors.response.eject(this.responseInterceptor);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
