import React, { FC } from 'react';
import { Form as UiKitForm, FormItem, FormList, TFormProps } from '@landlord-tech/ui-kit-opp';
import { validationRules, regexPatterns } from './validationRules';
import { TFormFieldOptions, TFormInstance } from './type';

const Form: FC<TFormProps> = ({ scrollToFirstError, ...props }) => {
  return <UiKitForm scrollToFirstError={scrollToFirstError && { behavior: 'smooth' }} {...props} />;
};

export default Form;
export { FormItem, FormList, validationRules, regexPatterns };
export type { TFormProps, TFormInstance, TFormFieldOptions };
