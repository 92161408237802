import { TMultiOptionTypeMaterialUI, TOptionTypeDB } from './general.types';

export type TPropertyDB = TOptionTypeDB;

export type TPropertyUI = TMultiOptionTypeMaterialUI;

export type TUnitDB = {
  id: string;
  number: string;
};

export type TUnitUI = TMultiOptionTypeMaterialUI;

export type TPropertyTypeUI = {
  title: string;
  keyWord: string;
};
export type TPropertyTypeDB = {
  key: string;
  displayName: string;
};

export enum EPropertiesTemplateTypeDB {
  'OPP' = 'OPP',
}

export enum EPropertiesTemplateTypeUI {
  'OPP' = 'OPP',
}

export type TPropertiesUploadDB = {
  type: EPropertiesTemplateTypeDB;
  file: File[];
};

export type TPropertiesUploadUI = {
  type: EPropertiesTemplateTypeUI;
  file: File[];
};

export type TPropertiesCSVUploadResultUI = {
  skippedProperties: Blob | null;
  skippedPropertiesCount: number;
  removedPropertiesCount: number;
  addedPropertiesCount: number;
  matchedPropertiesCount: number;
  totalPropertiesCount: number;
};

export type TPropertiesCSVUploadResultDB = {
  skippedProperties: string;
  skippedPropertiesCount: number;
  removedPropertiesCount: number;
  addedPropertiesCount: number;
  matchedPropertiesCount: number;
  totalPropertiesCount: number;
};

// export enum EPropertySource {
//   SYSTEM = 'SYSTEM',
//   RESMAN = 'RESMAN',
// }
// export type TPropertyTypeDB = {
//   id: string;
//   name: string;
//   photoUrl: string | null;
//   unitCount: number;
//   type: string;
//   address1: string;
//   address2: string;
//   city: string;
//   state: string;
//   zip: string;
//   source: EPropertySource;
//   defaultPolicy: {
//     id: string;
//   };
//   petsAllowed: boolean;
// };
