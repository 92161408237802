import React, { forwardRef, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MAppBar from '@material-ui/core/AppBar';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, MenuList } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAppDispatch } from 'contexts/PropertyEditContext';
import { impersonate as impersonateUser } from 'api/auth';
import Button from 'components/BaseComponents/Button';
import Dialog from 'components/BaseComponents/Dialog';
import useApi from 'components/Hooks/useAPI';
import ExitIcon from 'assets/images/exit.svg';
import { EUserRolesUI } from 'antd/data/types/user.types';
import { useDeleteImpersonationMutation } from 'antd/redux/apiSlices/user.slice';
import { useSignOutMutation } from 'antd/redux/apiSlices/auth.slice';
import { useAppSelector } from 'antd/redux/store';
import useShowToaster from 'components/Hooks/useShowToaster';
import { useStyles } from './appbar.style';
import { RoutesNames } from '../../RoutesNames';

const AppBar = forwardRef(({ handleMenuClick }, ref) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const showToaster = useShowToaster();
  const { users, userType, impersonateOn } = useAppSelector((state) => state.usersSlice);
  const matches = useMediaQuery('(max-width:768px)');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pagination } = useApi();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const [userToImpersonate, setUserToImpersonate] = useState(null);
  const [deleteImpersonation, { isLoading }] = useDeleteImpersonationMutation();
  const [signOut] = useSignOutMutation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    pagination.setPage(0);
    setAnchorEl(null);
  };

  const logOut = async () => {
    await signOut().unwrap();
    history.push(RoutesNames.SignIn);
  };

  const userImpersonate = () => {
    if (userType === EUserRolesUI.ADMIN) {
      dispatch({
        type: 'SET_LASTPOSITION',
        payload: pathname,
      });
    }

    impersonateUser(userToImpersonate);
  };

  useImperativeHandle(ref, () => ({
    logout: logOut,
  }));

  const removeImpersonation = async () => {
    try {
      await deleteImpersonation().unwrap();
    } catch (e) {
      showToaster({ type: 'error', title: 'Something went wrong!' });
    }
  };
  const profile = users[userType];
  return (
    <MAppBar className={classes.stickyMainHeader}>
      <Dialog
        text={`Are you sure you want to act as ${userToImpersonate?.name || ''}?`}
        confirmText='Yes'
        open={!!userToImpersonate}
        handleClose={() => setUserToImpersonate(null)}
        handleConfirm={userImpersonate}
      />
      <Toolbar className={classes.topBar}>
        {matches && pathname !== '/intro' && (
          <IconButton onClick={handleMenuClick} className={clsx(classes.customHover, classes.menuIcon)}>
            <MenuIcon />
          </IconButton>
        )}
        <div className={classes.buttonsWrapper}>
          {impersonateOn && (
            <>
              <Button
                onClick={removeImpersonation}
                version='bgWhite'
                loading={isLoading}
                className={classes.unImpersonate}
              >
                <img className={classes.exitIcon} src={ExitIcon} alt='' />
                Exit {profile.name} view
              </Button>
            </>
          )}
          <div className={classes.iconsContainer}>
            <IconButton className={classes.customHover}>
              <Link to={pathname === '/intro' ? '/intro' : '/profile'}>
                {profile && profile.profilePicture ? (
                  <img src={profile.profilePicture} alt='profile pic' className={classes.photo} />
                ) : (
                  <AccountCircle className={clsx(classes.headerIcons)} />
                )}
              </Link>
            </IconButton>
            {!matches && (
              <IconButton
                onClick={handleClick}
                aria-describedby={id}
                className={classes.customHover}
                data-testid='userdropdown'
              >
                <ExpandMoreIcon className={classes.headerIcons} />
              </IconButton>
            )}

            <Popover
              disableScrollLock
              style={{ borderRadius: '24px' }}
              className={classes.popover}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuList className={classes.navDropdown} onClick={handleClose}>
                <MenuItem disableGutters className={classes.dropdownItem}>
                  <Link to={pathname === '/intro' ? '/intro' : '/profile'}>Profile settings</Link>
                </MenuItem>
                {profile?.type === 'tenant' && (
                  <MenuItem disableGutters className={classes.dropdownItem}>
                    <Link to='/payment-settings'>Payment settings</Link>
                  </MenuItem>
                )}
                <MenuItem disableGutters className={classes.dropdownItem} onClick={logOut} data-testid='logOut'>
                  Log out
                </MenuItem>
              </MenuList>
            </Popover>
          </div>
        </div>
      </Toolbar>
    </MAppBar>
  );
});

export default AppBar;
