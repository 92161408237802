import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import {
  EResidenceStatusUi,
  TApplicantDetailsDB,
  TApplicantDetailsUI,
  TCollectDNAUi,
  TResidenceDB,
  TResidenceFilterDB,
  TResidenceFilterUI,
  TResidenceMoveInfoUI,
  TResidenceUI,
  TResidencyApplicationApplyUI,
  TResidencyApplicationRejectUI,
  TResidentDetailsDB,
  TResidentDetailsUI,
  TResidentMoveUi,
  TUnCollectDNAUi,
} from 'antd/data/types/residence.types';
import {
  collectDnaConverter,
  ResidentMoveConverter,
  residenceFilterConverter,
  residenceGetConverter,
  residenceMoveConverter,
  residentDetailsConverter,
  unCollectDnaConverter,
  applicantDetailsConverter,
  residencyApplicationApproveConverter,
  residencyApplicationRejectConverter,
  residenceStatusConverter,
} from 'antd/data/converters/residence.converters';
import { EResidenceUrl } from 'antd/data/urls/residence.url';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { TPageableDataParamUI } from 'antd/data/types/pagableData.type';
import { downloadFile } from 'antd/api/sevices/fileServices';

const URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCE}`;
const APPLICANT_URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCY}`;
export const residenceApi = authSplitApi('residenceApi', [
  'residence', // caching data get data tag names,
  'residentDetails',
  'applicantDetails',
  'csv',
]).injectEndpoints({
  endpoints: (build) => ({
    getResidencies: build.query<TResidenceUI[], { statuses: string[]; propertyIds?: string[]; unitIds?: string[] }>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.SEARCH}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TResidenceDB[]) => {
        return data?.map((item) => residenceGetConverter.fromDb(item)) || [];
      },
      providesTags: ['residence'], // for caching and update cache
    }),
    getResidentDetails: build.query<TResidentDetailsUI, string>({
      query(id) {
        return {
          url: `${URL}/${EResidenceUrl.PROFILE}`,
          method: 'GET',
          params: { residenceId: id },
        };
      },
      transformResponse: (data: TResidentDetailsDB) => residentDetailsConverter.fromDb(data),
      providesTags: ['residentDetails'], // for caching and update cache
    }),
    getApplicantDetails: build.query<TApplicantDetailsUI, string>({
      query(id) {
        return {
          url: `${APPLICANT_URL}/${EResidenceUrl.APPLICATION}/${EResidenceUrl.PROFILE}`,
          method: 'GET',
          params: { residencyApplicationId: id },
        };
      },
      transformResponse: (data: TApplicantDetailsDB) => applicantDetailsConverter.fromDb(data),
      providesTags: ['applicantDetails'], // for caching and update cache
    }),
    getResidenciesFilter: build.query<TResidenceFilterUI[], { statuses?: EResidenceStatusUi[] } | void>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.FILTER}/${EResidenceUrl.RESIDENCE}`,
          method: 'GET',
          ...(params && { params: { statuses: params?.statuses?.map((item) => residenceStatusConverter.toDb(item)) } }),
        };
      },
      transformResponse: (data: TResidenceFilterDB[]) => {
        return data?.map((item) => residenceFilterConverter.fromDb(item)) || [];
      },
    }),
    getResidenceMoveInfo: build.query<TResidenceMoveInfoUI, { residencyId?: string; propertyId?: string }>({
      query({ residencyId, propertyId }) {
        return {
          url: `${URL}/${residencyId}/${EResidenceUrl.MOVE}/${propertyId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TResidenceMoveInfoUI) => residenceMoveConverter.fromDb(data),
    }),
    getApproveResidencyVerify: build.query<TResidenceMoveInfoUI, { residencyId?: string; propertyId?: string }>({
      query({ residencyId, propertyId }) {
        return {
          url: `${APPLICANT_URL}/${EResidenceUrl.APPLICATION}/${EResidenceUrl.APPROVE}/${EResidenceUrl.VERIFY}`,
          method: 'GET',
          params: { residencyApplicationId: residencyId, propertyId },
        };
      },
      transformResponse: (data: TResidenceMoveInfoUI) => residenceMoveConverter.fromDb(data),
    }),
    residentMove: build.mutation<void, TResidentMoveUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.MOVE}`,
          method: 'PATCH',
          body: ResidentMoveConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails'],
    }),
    moveOutResident: build.mutation<void, string>({
      query(id) {
        return {
          url: `${URL}/${EResidenceUrl.TERMINATE}`,
          method: 'PATCH',
          body: { residenceId: id },
        };
      },
      invalidatesTags: ['residentDetails'],
    }),
    residentApplicationApprove: build.mutation<string, TResidencyApplicationApplyUI>({
      query(body) {
        return {
          url: `${APPLICANT_URL}/${EResidenceUrl.APPLICATION}/${EResidenceUrl.APPROVE}`,
          method: 'PATCH',
          body: residencyApplicationApproveConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails'],
    }),
    residentApplicationReject: build.mutation<void, TResidencyApplicationRejectUI>({
      query(body) {
        return {
          url: `${APPLICANT_URL}/${EResidenceUrl.APPLICATION}/${EResidenceUrl.REJECT}`,
          method: 'PATCH',
          body: residencyApplicationRejectConverter.toDb(body),
        };
      },
      invalidatesTags: ['applicantDetails'],
    }),
    collectDNA: build.mutation<void, TCollectDNAUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.DNA}/${EResidenceUrl.COLLECT}`,
          method: 'PATCH',
          body: collectDnaConverter.toDb(body),
        };
      },
    }),
    unCollectDNA: build.mutation<void, TUnCollectDNAUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.DNA}/${EResidenceUrl.UN_COLLECT}`,
          method: 'PATCH',
          body: unCollectDnaConverter.toDb(body),
        };
      },
    }),
    exportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${URL}/${EResidenceUrl.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'residents.csv');
            return null;
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetResidenciesQuery,
  useCollectDNAMutation,
  useUnCollectDNAMutation,
  useGetResidenciesFilterQuery,
  useGetResidenceMoveInfoQuery,
  useResidentMoveMutation,
  useGetResidentDetailsQuery,
  useMoveOutResidentMutation,
  useGetApplicantDetailsQuery,
  useResidentApplicationApproveMutation,
  useResidentApplicationRejectMutation,
  useGetApproveResidencyVerifyQuery,
  useLazyExportCSVQuery,
} = residenceApi;
