import { TAuthConverter, TConvertor, TFetchConverter } from '../types/converter.types';
import {
  EHPVisitation,
  EHPVisitationUI,
  TDocPacketGet,
  TDocPacketGetUI,
  THPContactInfo,
  THPContactInfoUI,
  EHPDocPacketTypesUI,
  EHPDocPacketTypes,
  EDocPacketStatusUi,
  EDocPacketStatus,
  THPVisitationGetUI,
  THPVisitationGet,
} from '../types/document.types';
import { documentFilesConvertor } from './animals.converter';

export const hpContactInfoConvertor: TAuthConverter<
  THPContactInfoUI,
  THPContactInfo,
  THPContactInfoUI,
  THPContactInfo
> = {
  toDb: (data) => ({
    name: data.name,
    emailAddress: data.emailAddress?.trim() || undefined,
    phoneNumber: data.phoneNumber ? `${data.phoneNumber[0] === '+' ? '' : '+'}${data.phoneNumber}` : undefined,
    faxNumber: data.faxNumber?.trim() || undefined,
  }),
  fromDb: (data) => ({
    name: data.name,
    emailAddress: data.emailAddress,
    phoneNumber: data.phoneNumber,
    faxNumber: data.faxNumber,
  }),
};

export const hpDocTypeConvertor: TConvertor<EHPDocPacketTypesUI, EHPDocPacketTypes> = {
  fromDb: (data) => {
    return EHPDocPacketTypesUI[EHPDocPacketTypes[data] as keyof typeof EHPDocPacketTypesUI];
  },
  toDb: (data) => {
    return EHPDocPacketTypes[EHPDocPacketTypesUI[data] as keyof typeof EHPDocPacketTypes];
  },
};

export const docPacketStatusConverter: TConvertor<EDocPacketStatusUi, EDocPacketStatus> = {
  fromDb: (data) => {
    const statuses: {
      [key in EDocPacketStatus]: EDocPacketStatusUi;
    } = {
      [EDocPacketStatus.NONE]: EDocPacketStatusUi.NONE,
      [EDocPacketStatus.VERIFIED]: EDocPacketStatusUi.VERIFIED,
    };
    return statuses[data];
  },
  toDb: (data) => {
    const statuses: {
      [key in EDocPacketStatusUi]: EDocPacketStatus;
    } = {
      [EDocPacketStatusUi.NONE]: EDocPacketStatus.NONE,
      [EDocPacketStatusUi.VERIFIED]: EDocPacketStatus.VERIFIED,
    };
    return statuses[data];
  },
};

export const hpVisitationTypeConvertor: TConvertor<EHPVisitationUI, EHPVisitation> = {
  fromDb: (data) => {
    return EHPVisitationUI[EHPVisitation[data] as keyof typeof EHPVisitationUI];
  },
  toDb: (data) => {
    return EHPVisitation[EHPVisitationUI[data] as keyof typeof EHPVisitation];
  },
};

export const hpVisitationConvertor: TFetchConverter<THPVisitationGetUI, THPVisitationGet> = {
  fromDb: (data) => {
    return {
      type: hpVisitationTypeConvertor.fromDb(data.type),
      websites: data?.websites?.map((item) => item.value),
    };
  },
};

export const getHPDocPacketsConvertor: TFetchConverter<TDocPacketGetUI, TDocPacketGet> = {
  fromDb: (data) => {
    return {
      creationDate: data.creationDate,
      hpContactInfo: hpContactInfoConvertor.fromDb(data.hpContactInfo),
      hpDocForms: (data.hpDocForms || []).map((item) => documentFilesConvertor.fromDb(item)) || [],
      hpDocLetters: (data.hpDocLetters || []).map((item) => documentFilesConvertor.fromDb(item)) || [],
      hpDocumentPacketId: data.hpDocumentPacketId,
      hpVisitation: data.hpVisitation ? hpVisitationConvertor.fromDb(data.hpVisitation) : undefined,
      type: hpDocTypeConvertor.fromDb(data.type),
      status: docPacketStatusConverter.fromDb(data.verificationStatus),
    };
  },
};
