import { TFileDataType, TFileDB, TFileUI } from './general.types';

export enum EHPVisitationUI {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}
export enum EHPVisitation {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}

export enum EHPDocPacketTypesUI {
  SA = 'SA',
  ESA = 'ESA',
}
export enum EHPDocPacketTypes {
  SA = 'SA',
  ESA = 'ESA',
}

export type THPDocFieldUpdateUI = {
  documentPacketId: string;
  value: string;
};

export type THPVisitationUI = { type: EHPVisitationUI; websites?: string[] };
export type THPVisitation = { type: EHPVisitation; websites?: string[] };

export type THPVisitationGetUI = THPVisitationUI;
export type THPVisitationGet = {
  type: EHPVisitation;
  websites?: { value: string }[];
};

export type THPContactInfoUI = {
  name: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
};
export type THPContactInfo = THPContactInfoUI;

export type TNameUpdateUI = THPDocFieldUpdateUI;
export type TNameUpdate = {
  documentPacketId: string;
  hpContactName: string | null;
};

export type TEmailUpdateUI = THPDocFieldUpdateUI;
export type TEmailUpdate = {
  documentPacketId: string;
  hpContactEmail: string | null;
};

export type TPhoneUpdateUI = THPDocFieldUpdateUI;
export type TPhoneUpdate = {
  documentPacketId: string;
  hpContactPhone: string | null;
};

export type TFaxUpdateUI = THPDocFieldUpdateUI;
export type TFaxUpdate = {
  documentPacketId: string;
  hpContactFaxNumber: string | null;
};

export type THPVisitationUpdateUI = {
  documentPacketId: string;
  hpVisitation: THPVisitationUI;
};
export type THPVisitationUpdate = {
  documentPacketId: string;
  hpVisitation: THPVisitation;
};

export type THPLetterUpdateUI = {
  hpDocumentPacketId: string;
  newHpLetters: TFileDB[];
  removedDocumentsIds?: string[];
};
export type THPLetterUpdate = {
  documentPacketId: string;
  newHpLetters: TFileDB[];
  removedHpLetterIds?: string[];
};

export type THPFormUpdateUI = {
  tenantId: string;
  hpDocumentPacketId: string;
  newHpForms: TFileDB[];
  removedHpFormIds?: string[];
};
export type THPFormUpdate = {
  tenantId: string;
  documentPacketId: string;
  newHpForms: TFileDB[];
  removedHpFormIds?: string[];
};

export type TDocPacketUI = {
  hpDocType: EHPDocPacketTypesUI;
  name: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string[];
  hpVisitation: EHPVisitationUI;
  hpDocPacketLetter?: TFileDB[];
};
export type TDocPacket = {
  hpDocType: EHPDocPacketTypes;
  hpContactInfo: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFileDB[];
};

export enum EDocPacketStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}
export enum EDocPacketStatusUi {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}

export type TDocPacketGetUI = {
  creationDate: string;
  hpContactInfo: THPContactInfoUI;
  hpDocForms?: TFileDataType[];
  hpDocLetters?: TFileDataType[];
  hpDocumentPacketId: string;
  hpVisitation?: THPVisitationGetUI;
  type: EHPDocPacketTypesUI;
  status: EDocPacketStatusUi;
};

export type TDocPacketGet = {
  creationDate: string;
  hpContactInfo: THPContactInfo;
  hpDocForms?: TFileDataType[];
  hpDocLetters?: TFileDataType[];
  hpDocumentPacketId: string;
  hpVisitation?: THPVisitationGet;
  type: EHPDocPacketTypes;
  verificationStatus: EDocPacketStatus;
};
