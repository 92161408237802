import { TCreateConverter } from '../types/converter.types';
import { TUpdateBusinessName, TUpdateBusinessNameUI } from '../types/landlord.types';

export const updateBusinessNameConverter: TCreateConverter<TUpdateBusinessNameUI, TUpdateBusinessName> = {
  toDb: ({ name, propertyManagementId }) => {
    return {
      name,
      propertyManagementId,
    };
  },
};
