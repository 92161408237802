import { TAuthConverter, TConvertor, TCreateConverter, TFetchConverter } from 'antd/data/types/converter.types';
import {
  EPropertiesTemplateTypeDB,
  EPropertiesTemplateTypeUI,
  TPropertiesCSVUploadResultDB,
  TPropertiesCSVUploadResultUI,
  TPropertiesUploadUI,
  TPropertyDB,
  TPropertyTypeDB,
  TPropertyTypeUI,
  TPropertyUI,
  TUnitDB,
  TUnitUI,
} from 'antd/data/types/property.types';
import { b64toBlob } from 'antd/api/sevices/fileServices';

export const propertiesGetConverter: TFetchConverter<TPropertyUI, TPropertyDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      value: data.id,
    };
  },
};

export const getUnitsCoverter: TFetchConverter<TUnitUI, TUnitDB> = {
  fromDb: (data) => {
    return {
      name: data.number,
      value: data.id,
    };
  },
};

export const propertyTypeConverter: TFetchConverter<TPropertyTypeUI, TPropertyTypeDB> = {
  fromDb: (data) => ({
    title: data.displayName,
    keyWord: data.key,
  }),
};

export const propertiesTemplateTypeConverter: TConvertor<EPropertiesTemplateTypeUI, EPropertiesTemplateTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EPropertiesTemplateTypeDB]: EPropertiesTemplateTypeUI } = {
      [EPropertiesTemplateTypeDB.OPP]: EPropertiesTemplateTypeUI.OPP,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EPropertiesTemplateTypeUI]: EPropertiesTemplateTypeDB } = {
      [EPropertiesTemplateTypeUI.OPP]: EPropertiesTemplateTypeDB.OPP,
    };
    return dataMapper[data];
  },
};
export const propertyCsvUploadConverter: TAuthConverter<
  TPropertiesUploadUI,
  FormData,
  TPropertiesCSVUploadResultUI,
  TPropertiesCSVUploadResultDB
> = {
  toDb: (data) => {
    const formData = new FormData();
    formData.append('type', propertiesTemplateTypeConverter.toDb(data.type));
    data.file.forEach((item) => {
      formData.append('file', new Blob([item], { type: 'application/json' }));
    });
    return formData;
  },
  fromDb: (data) => {
    let file = null;
    if (data.skippedProperties) {
      file = b64toBlob(data.skippedProperties, 'text/csv');
    }
    return {
      skippedProperties: file,
      skippedPropertiesCount: data.skippedPropertiesCount,
      removedPropertiesCount: data.removedPropertiesCount,
      addedPropertiesCount: data.addedPropertiesCount,
      matchedPropertiesCount: data.matchedPropertiesCount,
      totalPropertiesCount: data.totalPropertiesCount,
    };
  },
};
