import styled from 'styled-components';
import { Title, Text, SecondaryText, Link, Paragraph } from '@landlord-tech/ui-kit-opp';
import { TTextStyledProps, TLinkStyledProps, TTitleStyledProps, TParagraphStyledProps } from './Typography.types';

export const STitle = styled(Title)<TTitleStyledProps>`
  &&& {
  }
`;

export const SText = styled(Text)<TTextStyledProps>`
  &&& {
  }
`;

export const SSecondaryText = styled(SecondaryText)<TTextStyledProps>`
  &&& {
  }
`;

export const SLink = styled(Link)<TLinkStyledProps>`
  &&& {
  }
`;

export const SParagraph = styled(Paragraph)<TParagraphStyledProps>`
  &&& {
    margin-bottom: 0px;
  }
`;
