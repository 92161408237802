import React, { createRef, memo, Suspense, useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAppDispatch, useAppState } from 'contexts/PropertyEditContext';

import Alert from 'components/BaseComponents/Alert';

import clsx from 'clsx';
import AppBar from './components/AppBar';
import SideBar from './components/SideBar';
import SuspenseLoading from './components/SuspenseLoading';

const useStyles = makeStyles((theme) => ({
  mainBody: {
    background: theme.backgroundColor,
    flexGrow: 1,
    minHeight: '100vh',
    position: 'relative',
    maxWidth: '100%',
  },
  shirinkWidth: {
    maxWidth: 'calc(100% - 260px)',
    '@media(max-width:768px)': {
      maxWidth: '100%',
    },
  },
  mainWrapper: {
    display: 'flex',
  },
  loader: {
    display: 'none',
  },
  bodyWrapper: {
    position: 'relative',
  },
}));

function SidebarRoutes({ children, userType }) {
  const appbarRef = useRef({
    logout: () => {},
  });
  const sidebarRef = createRef();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { alertObject } = useAppState();
  const dispatch = useAppDispatch();
  const [isShirink, setIsShirink] = useState(false);

  const classes = useStyles();
  const [refresh, setRefresh] = useState(new Date());

  function handleMenuClick() {
    setSideBarOpen(!sideBarOpen);
  }

  function handleClose() {
    dispatch({
      type: 'CLOSE_ALERT',
    });
  }

  const Loading = (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );

  const checkSidebarState = () => {
    if (!sidebarRef.current) {
      return;
    }
    setIsShirink(sidebarRef.current.isSideBarExist());
  };

  const refreshSideBar = () => setRefresh(new Date());

  useEffect(() => {
    if (!sidebarRef.current) {
      return;
    }

    checkSidebarState();
    window.addEventListener('resize', refreshSideBar);

    return () => {
      window.removeEventListener('resize', refreshSideBar);
    };
  }, [sidebarRef]);

  if (!userType) {
    return Loading;
  }

  return (
    <div className={classes.mainWrapper}>
      <SideBar
        key={refresh}
        onClose={handleMenuClick}
        sideBarOpen={sideBarOpen}
        onLogout={appbarRef.current.logout}
        ref={sidebarRef}
      />
      <div className={clsx(classes.mainBody, { [classes.shirinkWidth]: isShirink })}>
        <Alert severity={alertObject.severity} fadeout alertText={alertObject.message} onClose={handleClose} />
        <AppBar handleMenuClick={handleMenuClick} ref={appbarRef} />
        <Suspense fallback={<SuspenseLoading />}>{children}</Suspense>
      </div>
    </div>
  );
}

export default memo(SidebarRoutes);
