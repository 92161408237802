import { uploadCSVExecuteConverter, uploadCSVPreviewConverter } from 'antd/data/converters/integration.converter';
import { TFileDB } from 'antd/data/types/general.types';
import {
  TIntegrationDataUI,
  TIntegrationExecutionResultProps,
  TIntegrationResultProps,
  TIntegrationResultPropsUI,
  TIntegrationType,
} from 'antd/data/types/integration.types';
import { EIntegrationUrls } from 'antd/data/urls/integration.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.CSV}/${EIntegrationUrls.INTEGRATION}`;

export const integrationApi = authSplitApi('integration', []).injectEndpoints({
  endpoints: (build) => ({
    integrationPreview: build.mutation<TIntegrationResultPropsUI, TIntegrationDataUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EIntegrationUrls.PREVIEW}`,
          method: 'POST',
          body: uploadCSVPreviewConverter.toDb(body),
        };
      },
      transformResponse: (data: TIntegrationResultProps) => uploadCSVPreviewConverter.fromDb(data),
    }),
    integrationExecute: build.mutation<TIntegrationExecutionResultProps, TIntegrationDataUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EIntegrationUrls.EXECUTE}`,
          method: 'POST',
          body: uploadCSVExecuteConverter.toDb(body),
        };
      },
      transformResponse: (data: TIntegrationExecutionResultProps) => uploadCSVExecuteConverter.fromDb(data),
    }),
    getIntegrationCSVTemplate: build.query<{ templateUrl: string }, { type: TIntegrationType }>({
      query(params) {
        return {
          url: `${baseUrl}/${EIntegrationUrls.TEMPLATE}`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useIntegrationPreviewMutation, useIntegrationExecuteMutation, useGetIntegrationCSVTemplateQuery } =
  integrationApi;
