export enum EResidenceUrl {
  RESIDENCE = 'residence',
  VERIFY = 'verify',
  RESIDENCY = 'residency',
  APPROVE = 'approve',
  REJECT = 'reject',
  APPLICATION = 'application',
  PROFILE = 'profile',
  SEARCH = 'search',
  ANIMALS = 'animals',
  ANIMAL = 'animal',
  FILTER = 'filter',
  TERMINATE = 'terminate',
  CURRENT = 'current',
  MOVE = 'move',
  DNA = 'dna',
  COLLECT = 'collect',
  UN_COLLECT = 'uncollect',
  EXPORT = 'export',
  STATISTICS = 'statistics',
  DASHBOARD = 'dashboard',
  CONVERTED = 'converted',
  PREVENTED = 'prevented',
}
