import { validationRules} from "antd/components/Form";
import { TLoginFields } from "./Login.types";


export const loginFields:TLoginFields = {
    email: {
        name: 'email',
        label: 'Email address',
        rules: [
            { required: true, message: 'Please enter your email address!' },
            { type: 'email', message: 'Incorrect Email Address!' },
        ],
        placeholder: 'Enter email address',
    },
    password: {
        name: 'password',
        label: 'Password',
        rules: [
            ...validationRules.password,
            validationRules.required('password'),
            validationRules.withoutSpace('Password'),
        ],
        placeholder: 'Enter password',
    },
};