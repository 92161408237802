import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EResidenceUrl } from 'antd/data/urls/residence.url';
import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import {
  EAnimalStatusDB,
  TAnimalDB,
  TAnimalDetail,
  TAnimalDetailUi,
  TAnimalsStatisticsDB,
  TAnimalsStatisticsUI,
  TAnimalTerminateDB,
  TAnimalTerminateUI,
  TAnimalUI,
  TApplicantAnimalDB,
  TApplicantAnimalUI,
  TConvertedAnimalDB,
  TConvertedAnimalUI,
  TPreventedAnimalDB,
  TPreventedAnimalUI,
} from 'antd/data/types/animals.types';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import {
  animalConverter,
  animalTerminateConverter,
  applicantAnimalConverter,
  convertedAnimalConverter,
  getAnimalConverter,
  getAnimalStatisticsConverter,
  preventedAnimalConverter,
} from 'antd/data/converters/animals.converter';
import { EApplicationUrls } from 'antd/data/urls/application.url';
import { downloadFile } from 'antd/api/sevices/fileServices';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCE}`;

export const animalApi = authSplitApi('animalApi', [
  'animal',
  'animals',
  'terminatedAnimals',
  'applicantAnimals',
  'convertedAnimals',
  'preventedAnimals',
]).injectEndpoints({
  endpoints: (build) => ({
    getAnimals: build.query<TPageableDataWithContentUi<TAnimalUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMAL}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, { statuses: EAnimalStatusDB.ACTIVE }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => animalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['animals'], // for caching and update cache
    }),
    getAnimal: build.query<TAnimalDetailUi, string>({
      query(id) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMAL}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['animal'],
      transformResponse: (data: TAnimalDetail) => getAnimalConverter.fromDb(data),
    }),
    getAnimalStatistics: build.query<TAnimalsStatisticsUI, void>({
      query() {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.STATISTICS}/${EResidenceUrl.DASHBOARD}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TAnimalsStatisticsDB) => getAnimalStatisticsConverter.fromDb(data),
    }),
    getTerminatedAnimals: build.query<TPageableDataWithContentUi<TAnimalUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMAL}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            statuses: EAnimalStatusDB.TERMINATED,
          }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => animalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['terminatedAnimals'], // for caching and update cache
    }),
    terminateAnimal: build.mutation<TAnimalTerminateDB, TAnimalTerminateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.TERMINATE}`,
          method: 'PATCH',
          body: animalTerminateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'terminatedAnimals'],
    }),
    getApplicantAnimals: build.query<TPageableDataWithContentUi<TApplicantAnimalUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EApplicationUrls.APPLICATION}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}, true),
        };
      },
      providesTags: ['applicantAnimals'],
      transformResponse: (data: TPageableDataWithContent<TApplicantAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: (data?.content || []).map((item) => applicantAnimalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
    }),
    getConvertedAnimals: build.query<TPageableDataWithContentUi<TConvertedAnimalUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.CONVERTED}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}, true),
        };
      },
      providesTags: ['convertedAnimals'],
      transformResponse: (data: TPageableDataWithContent<TConvertedAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: (data?.content || []).map((item) => convertedAnimalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
    }),
    getPreventedAnimals: build.query<TPageableDataWithContentUi<TPreventedAnimalUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.PREVENTED}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}, true),
        };
      },
      providesTags: ['preventedAnimals'],
      transformResponse: (data: TPageableDataWithContent<TPreventedAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: (data?.content || []).map((item) => preventedAnimalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
    }),
    applicantAnimalsExportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EApplicationUrls.APPLICATION}/${EApplicationUrls.ANIMALS}/${EApplicationUrls.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'applicant animals.csv');
            return null;
          },
        };
      },
    }),
    currentAnimalsExportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.CURRENT}/${EResidenceUrl.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'current animals.csv');
            return null;
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAnimalsQuery,
  useGetAnimalQuery,
  useGetAnimalStatisticsQuery,
  useGetTerminatedAnimalsQuery,
  useGetConvertedAnimalsQuery,
  useGetPreventedAnimalsQuery,
  useTerminateAnimalMutation,
  useGetApplicantAnimalsQuery,
  useLazyCurrentAnimalsExportCSVQuery,
  useLazyApplicantAnimalsExportCSVQuery,
} = animalApi;
